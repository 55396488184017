import { Box, Button } from '@mui/material';
import { TargetType } from '@plotr/plotr-multiplayer-data/src';
import { useState } from 'react';
import useRulesets from '~/src/features/dynamic-map/hooks/useRulesets';
import RulesList from '~/src/features/rules-collapse/RulesList';
import AddRuleForm from '~/src/features/rules-collapse/AddRule.form';

type RuleCollapseSection = {
  targetType: TargetType;
};

const RulesCollapseSection = ({ targetType }: RuleCollapseSection) => {
  const [isCreating, setIsCreating] = useState(false);
  const rules = useRulesets()
    .filter((rule) => rule.targetType === targetType)
    ?.sort((a, b) => a?.sourceType.localeCompare(b?.sourceType));

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={'30rem'}
      sx={{ overflowY: 'auto' }}
    >
      {!isCreating ? (
        <>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => setIsCreating((prev) => !prev)}
          >
            Create a new rule
          </Button>
          <RulesList rules={rules} />
        </>
      ) : (
        <AddRuleForm
          targetType={targetType}
          closeForm={() => setIsCreating(false)}
        />
      )}
    </Box>
  );
};

export default RulesCollapseSection;
