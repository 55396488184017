import LockIcon from '@mui/icons-material/Lock';
import { ReactNode, useState } from 'react';
import HubspotModal from '../HubspotModal';

interface BlurTextLockProps {
  children: ReactNode;
  intensity: number;
  contextString: 'mobile' | 'sonar';
}

const BlurTextLock: React.FC<BlurTextLockProps> = ({
  children,
  intensity,
  contextString,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleLockClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <span
        style={{
          filter: `blur(${Math.floor(intensity ?? 1)}px)`,
          userSelect: 'none', // don't allow selecting text
        }}
      >
        {children}
      </span>
      <LockIcon
        onClick={handleLockClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '-25px', // Adjust this value as needed for positioning
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          color: '#000',
        }}
      />
      <HubspotModal
        open={isModalOpen}
        onClose={handleModalClose}
        formType={contextString} // Pass the context to determine the form type
      />
    </div>
  );
};

export default BlurTextLock;
