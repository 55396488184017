import { Box, Paper, Typography } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import turfBboxPolygon from '@turf/bbox-polygon';
import axios from 'axios';
import { Feature, Polygon } from 'geojson';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import formatDayTime from '~/src/common/helpers/formatDayTime';
import usePrevious from '~/src/common/hooks/usePrevious';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import { GroupComponentProps } from '../../../hooks/useLayersStore';
import useTrafficStore from '../../../hooks/useTrafficStore';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export const TrafficColor = {
  Light: '#f1c5cc',
  Medium: '#c76bb3',
  Heavy: '#950097',
};

export default function TrafficSource(props: GroupComponentProps) {
  const { enabled, opacity } = props;

  const map = useMapContext();

  type PopupInfo = {
    segmentId?: string;
    lngLat: { lng: number; lat: number };
    averageTraffic: any; // Adjust this type based on the expected value
  } | null;

  const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);
  const layerInfo = useTrafficStore((state) => state.layerInfo);
  const { max: trafficMax, min: trafficMin } = useTrafficStore(
    (state) => state.trafficRange
  );
  const fetchPermission = useTrafficStore((state) => state.fetchPermission);
  const hasPermission = useTrafficStore((state) => state.hasPermission);
  const trafficEnabled = useTrafficStore((state) => state.trafficEnabled);
  const setTrafficEnabled = useTrafficStore((state) => state.setTrafficEnabled);
  const selectedSegments = useTrafficStore(
    (state) => state.selectedTrafficSegments
  );
  const setSelectedSegments = useTrafficStore(
    (state) => state.setSelectedTrafficSegments
  );

  const [viewportBoundary, setViewportBoundary] =
    useState<Feature<Polygon> | null>(null);
  const previousViewportBoundary = usePrevious(viewportBoundary);

  const setTrafficRange = useTrafficStore((state) => state.setTrafficRange);
  const { selectedDay, selectedHour, direction } = useTrafficStore((state) => ({
    selectedDay: state.trafficSelection.selectedDay,
    selectedHour: state.trafficSelection.selectedHour,
    direction: state.trafficSelection.direction,
  }));
  const [trafficVisExpression, setTrafficVisExpression] = useState<
    mapboxgl.Expression | undefined
  >(undefined);
  const [trafficReady, setTrafficReady] = useState(false);

  const { accessToken } = useAccessToken();

  const maxZoomHeight = 12;

  //update viewport boundary when map moves
  useEffect(() => {
    if (enabled && map) {
      const handleMoveEnd = () => {
        if (map.getZoom() >= maxZoomHeight) {
          const bounds = map.getBounds();
          const bbox: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
          ];
          const geoJSON: Feature<Polygon> = turfBboxPolygon(bbox);
          setViewportBoundary(geoJSON);
        } else {
          setViewportBoundary(null);
        }
      };

      handleMoveEnd(); // set initial boundary

      map.on('moveend', handleMoveEnd);

      return () => {
        map.off('moveend', handleMoveEnd);
      };
    }
    return;
  }, [enabled, map]);

  useEffect(() => {
    if (trafficEnabled !== enabled) {
      setTrafficEnabled(enabled);
    }
  }, [enabled, setTrafficEnabled, trafficEnabled]);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const { data } = await axios.post(
          `${env.API_V2}/segments/get_segments`,
          {
            boundaryGeoJSON: viewportBoundary,
            columns: ['streetlight_id'],
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const segments = data.map(
          (segment: { streetlight_id: string }) => segment.streetlight_id
        );
        setSelectedSegments(segments);
      } catch (error) {
        console.error('Error fetching segments:', error);
      }
    };

    // Only reset state if not currently fetching
    if (
      viewportBoundary != null &&
      viewportBoundary !== previousViewportBoundary
    ) {
      fetchSegments();
    }
  }, [setSelectedSegments, viewportBoundary, previousViewportBoundary]);

  const fetchHighLow = async () => {
    try {
      const { data } = await axios.post(
        `${env.API_V2}/vehicles/get_high_low_traffic`,
        {
          segmentIds: selectedSegments,
          dayType: selectedDay,
          dayPart: selectedHour,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const summary = data.aggregatedData;
      setTrafficRange({ min: summary.minVolume, max: summary.maxVolume });
    } catch (error) {
      console.error('Error fetching high low traffic:', error);
      setTrafficRange({ max: 0, min: 0 });
    }
  };

  const trafficLayerIds = useMemo(
    () =>
      layerInfo
        .filter(
          (layer) => layer?.fields && Object.keys(layer.fields).length > 0
        )
        .map((layer) => `traffic_volume-${layer.id}`),
    [layerInfo]
  );

  const calculateAverageTrafficForFeature = useCallback(
    (feature: any): number => {
      const properties = feature.properties;

      let totalTraffic = 0;

      const hours =
        selectedHour === -1 ? [...Array(24).keys()] : [selectedHour];

      if (selectedDay === 0) {
        for (let i = 1; i <= 7; i++) {
          const dailyTraffic = hours.reduce((acc, hour) => {
            const hourString = hour < 10 ? '0' + hour : hour.toString();
            const key = `${i}-${hourString}-${direction}`;
            return properties[key] !== undefined ? acc + properties[key] : acc;
          }, 0);

          totalTraffic += dailyTraffic;
        }
      } else {
        totalTraffic = hours.reduce((acc, hour) => {
          const hourString = hour < 10 ? '0' + hour : hour.toString();
          const key = `${selectedDay}-${hourString}-${direction}`;
          return properties[key] !== undefined ? acc + properties[key] : acc;
        }, 0);
      }

      return selectedDay > 0 ? totalTraffic : totalTraffic / 7;
    },
    [direction, selectedDay, selectedHour]
  );

  const trafficTilesetURL = 'mapbox://luketruitt1.plotr-traffic';

  const generateTrafficAverageExpression = (
    selectedDay: number,
    selectedHour: number,
    direction: number
  ): mapboxgl.Expression => {
    const dayExpressions: any[] = [];

    const hours = selectedHour === -1 ? [...Array(24).keys()] : [selectedHour]; // include 00 hour when ready

    if (selectedDay === 0) {
      for (let i = 1; i <= 7; i++) {
        hours.forEach((hour) => {
          const hourString = hour < 10 ? '0' + hour : hour.toString();
          dayExpressions.push([
            'coalesce',
            ['get', `${i}-${hourString}-${direction}`],
            0,
          ]);
        });
      }
    } else {
      hours.forEach((hour) => {
        const hourString = hour < 10 ? '0' + hour : hour.toString();
        dayExpressions.push([
          'coalesce',
          ['get', `${selectedDay}-${hourString}-${direction}`],
          0,
        ]);
      });
    }

    const divisor = selectedDay === 0 ? 7 : 1;
    const averageExpression = ['/', ['+', ...dayExpressions], divisor];

    const trafficTiers = {
      high: trafficMin + (trafficMax - trafficMin) * 0.95,
      medium: trafficMin + (trafficMax - trafficMin) * 0.5,
      low: trafficMin + (trafficMax - trafficMin) * 0.25,
    };

    const noInterpolation =
      trafficTiers.high === trafficTiers.medium &&
      trafficTiers.medium === trafficTiers.low;

    return [
      'interpolate',
      ['linear'],
      averageExpression,
      ...(noInterpolation
        ? [0, 'transparent']
        : [
            ...[trafficTiers.low, TrafficColor.Light],
            ...[trafficTiers.medium, TrafficColor.Medium],
            ...[trafficTiers.high, TrafficColor.Heavy],
          ]),
    ];
  };

  useEffect(() => {
    if (accessToken != null) {
      fetchPermission(accessToken);
    }
  }, [accessToken, fetchPermission]);

  useEffect(() => {
    if (selectedSegments.length === 0) return;
    fetchHighLow();
  }, [selectedSegments, selectedDay, selectedHour]);

  useEffect(() => {
    if (selectedSegments.length === 0) return;
    if (trafficMin === 0 && trafficMax === 0) return;
    setTrafficVisExpression(
      generateTrafficAverageExpression(selectedDay, selectedHour, direction)
    );
  }, [trafficMin, trafficMax]);

  useEffect(() => {
    if (!trafficLayerIds) return;
    if (!map) return;
    if (!trafficReady || !trafficEnabled) return;
    const onHover = (event: any) => {
      if (!trafficReady) return;
      const features = map.queryRenderedFeatures(event.point, {
        layers: trafficLayerIds,
      });
      if (features && features.length) {
        const feature = features[0];
        const averageTraffic = calculateAverageTrafficForFeature(feature);

        setPopupInfo({
          segmentId: feature.properties?.streetlightId,
          lngLat: event.lngLat,
          averageTraffic: averageTraffic,
        });
      } else {
        setPopupInfo(null);
      }
    };

    map.on('mousemove', onHover);

    return () => {
      map.off('mousemove', onHover);
    };
  }, [
    trafficLayerIds,
    trafficReady,
    selectedDay,
    selectedHour,
    trafficEnabled,
    map,
    calculateAverageTrafficForFeature,
  ]);

  useEffect(() => {
    if (
      selectedSegments.length > 0 &&
      trafficMin >= 0 &&
      trafficMax > 0 &&
      trafficVisExpression
    ) {
      setTrafficReady(true);
    } else {
      setTrafficReady(false);
    }
  }, [selectedSegments, trafficMin, trafficMax, trafficVisExpression]);

  const stateLabelIds = useLayerIds((layer) =>
    layer.id.startsWith('state-label')
  );

  return map != null && hasPermission ? (
    <>
      <Source id={'TRAFFIC_VOLUME'} type="vector" url={trafficTilesetURL}>
        {trafficEnabled &&
          layerInfo
            .filter(
              (layer) => layer?.fields && Object.keys(layer.fields).length > 0
            )
            .map((layer) => {
              const layerId = `traffic_volume-${layer.id}`;

              return (
                <Layer
                  id={layerId}
                  key={layerId}
                  type="line"
                  source={'TRAFFIC_VOLUME'}
                  source-layer={layer.id}
                  minzoom={6}
                  paint={{
                    'line-color': trafficVisExpression ?? 'transparent',
                    'line-width': [
                      'interpolate',
                      ['linear'],
                      ['zoom'],
                      6,
                      2,
                      12,
                      4,
                      18,
                      8,
                    ],
                    'line-opacity': opacity ?? 0,
                  }}
                  beforeId={stateLabelIds[0]}
                  filter={['in', 'streetlightId', ...selectedSegments]}
                />
              );
            })}
      </Source>
      {trafficEnabled && trafficReady && popupInfo && (
        <Popup
          anchor="top"
          longitude={popupInfo.lngLat.lng}
          latitude={popupInfo.lngLat.lat}
          closeButton={false}
        >
          <Paper elevation={0} style={{ padding: '8px' }}>
            <Typography variant="subtitle1" gutterBottom>
              <span role="img" aria-label="car emoji">
                🚙
              </span>{' '}
              Average Traffic <br />
              {selectedDay === 0
                ? 'Daily'
                : formatDayTime(selectedDay, selectedHour)}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h6">
                {numeral(Math.round(popupInfo.averageTraffic)).format('0,0')}
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                vehicles
              </Typography>
            </Box>
          </Paper>
        </Popup>
      )}
    </>
  ) : null;
}
