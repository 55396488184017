import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { MobileDataConfig } from '~/src/constants';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import BlurTextLock from '../BlurTextLock';
import { calculatePercentile } from '../MobileDataUtils';
import StatDisplay from '../StatDisplay';
import LikelihoodDisplay from './LikelihoodDisplay';
import RankingProgressBar from './RankingProgressBar';

const {
  MARKET_RANKING,
  MARKET_RANKING_DESCRIPTION,
  NATIONAL_RANKING,
  NATIONAL_RANKING_DESCRIPTION,
  NATIONAL_RANKING_PERCENTILE,
  MARKET_RANKING_PERCENTILE,
  MOBILE_STAT_FONT_SIZE,
  MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE,
  SONAR_READ_PERMISSION,
  MIN_TREND_YEAR,
  MAX_RANKING_YEAR,
} = MobileDataConfig;

const SonarCard: React.FC = () => {
  const poiData = useDemographicStore((state) => state.poiData);
  const sonarData = useDemographicStore((state) => state.sonarData);

  const sonarDataIsLoading = useDemographicStore(
    (state) => state.sonarDataIsLoading
  );
  const clickedPOIFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const sonarDataYear = useDemographicStore((state) => state.sonarDataYear);
  const setSonarDataYear = useDemographicStore(
    (state) => state.setSonarDataYear
  );

  const hasPermission = usePermissionCheck(SONAR_READ_PERMISSION);

  const { city, region } = clickedPOIFeature?.properties || {};
  const { cbsaName } = poiData || {};
  const {
    likelihood_to_close,
    ntlRanking,
    marketRanking,
    total_number_of_market_ranked_brand_stores,
    total_number_of_national_ranked_brand_stores,
  } = sonarData || {};

  const getCBSAName = () => {
    if (cbsaName) {
      return `${cbsaName}`;
    }
    return `${city}, ${region}`;
  };

  const marketRankDisplay = (
    <>
      <Grid item xs={12}>
        <StatDisplay
          label={MARKET_RANKING}
          value={
            marketRanking && total_number_of_market_ranked_brand_stores ? (
              <>
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={MOBILE_STAT_FONT_SIZE}
                  mr={1}
                >
                  {hasPermission ? (
                    marketRanking
                  ) : (
                    <BlurTextLock intensity={7} contextString="sonar">
                      4/1000
                    </BlurTextLock>
                  )}
                </Typography>
                {' / '}
                <Typography
                  component="span"
                  fontSize={MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE}
                  ml={2}
                >
                  {hasPermission && total_number_of_market_ranked_brand_stores}
                </Typography>
              </>
            ) : (
              <BlurTextLock intensity={7} contextString="sonar">
                4/1000
              </BlurTextLock>
            )
          }
          description={<>{MARKET_RANKING_DESCRIPTION(getCBSAName())}</>}
        />
      </Grid>
      {marketRanking &&
        total_number_of_market_ranked_brand_stores &&
        hasPermission && (
          <Grid item xs={12}>
            <RankingProgressBar
              value={parseInt(
                calculatePercentile(
                  marketRanking,
                  total_number_of_market_ranked_brand_stores
                )
              )}
              title={MARKET_RANKING_PERCENTILE}
            />
          </Grid>
        )}
    </>
  );

  const nationalRankDisplay = (
    <>
      <Grid item xs={12}>
        <StatDisplay
          label={NATIONAL_RANKING}
          value={
            ntlRanking && total_number_of_national_ranked_brand_stores ? (
              <>
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={MOBILE_STAT_FONT_SIZE}
                  mr={1}
                >
                  {hasPermission ? (
                    ntlRanking
                  ) : (
                    <BlurTextLock intensity={7} contextString="sonar">
                      4/1000
                    </BlurTextLock>
                  )}
                </Typography>
                {' / '}
                <Typography
                  component="span"
                  fontSize={MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE}
                  ml={2}
                >
                  {hasPermission &&
                    total_number_of_national_ranked_brand_stores}
                </Typography>
              </>
            ) : (
              <BlurTextLock intensity={7} contextString="sonar">
                4/1000
              </BlurTextLock>
            )
          }
          description={
            <>
              This rank is based off of{' '}
              <Typography component="span" fontWeight="bold">
                {hasPermission ? (
                  total_number_of_national_ranked_brand_stores
                ) : (
                  <span
                    style={{
                      filter: `blur(3px)`,
                      userSelect: 'none', // don't allow selecting text
                    }}
                  >
                    99999
                  </span>
                )}
              </Typography>
              {' total locations. ' +
                NATIONAL_RANKING_DESCRIPTION(
                  clickedPOIFeature?.properties?.name
                )}
            </>
          }
        />
      </Grid>
      {ntlRanking &&
        total_number_of_national_ranked_brand_stores &&
        hasPermission && (
          <Grid item xs={12}>
            <RankingProgressBar
              value={parseInt(
                calculatePercentile(
                  ntlRanking,
                  total_number_of_national_ranked_brand_stores
                )
              )}
              title={NATIONAL_RANKING_PERCENTILE}
            />
          </Grid>
        )}
    </>
  );

  const sonarDisplay = (
    <Grid item xs={12}>
      <LikelihoodDisplay
        likelihood={(likelihood_to_close as 'high' | 'medium' | 'low') || 'low'}
        isBlurred={!hasPermission}
      />
    </Grid>
  );

  const rankingYearPicker = (
    <Grid item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year']}
          label="Year"
          value={new Date(sonarDataYear, 0)}
          onChange={(newValue) => {
            if (newValue) {
              setSonarDataYear(newValue.getFullYear());
            }
          }}
          minDate={new Date(Number(MIN_TREND_YEAR), 0)}
          maxDate={new Date(Number(MAX_RANKING_YEAR), 0)}
          sx={{ width: '100%' }}
        />
      </LocalizationProvider>
    </Grid>
  );

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: 3,
        width: '90%',
        margin: 2,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      {sonarDataIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box position="relative">
          <Grid container spacing={2} justifyContent="center">
            {rankingYearPicker}
            {sonarDisplay}
            {nationalRankDisplay}
            {marketRankDisplay}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SonarCard;
