import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { FeatureCollection } from 'geojson';
import { useEffect, useMemo, useState } from 'react';
import useBlockGroupsStore from '~/src/features/dynamic-map/hooks/useBlockGroupStore';
import useTrafficStore from '~/src/features/dynamic-map/hooks/useTrafficStore';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import { checkPermission } from '~/src/global/services/permissionService';
import useLayersStore, {
  ControllableLayerGroup,
  LayerCard,
  LayerDataConfig,
  LayerStyleConfig,
} from '../../../dynamic-map/hooks/useLayersStore';
import getMapLayerDefinitions, {
  Color,
} from '../../data/getMapLayerDefinitions';
import EditLayerModal from './components/EditLayerModal/EditLayerModal';
import LayerImageCategories from './components/EditLayerModal/LayerImageCategories';
import SingleLayerCard from './components/SingleLayerCard';

export enum LayerType {
  Insights = 'Insights Layer',
  Enterprise = 'Enterprise Layer',
  IndustryData = 'Industry Data Layer',
  LayerSet = 'Layer Set',
}

interface CustomLabel {
  show?: boolean;
  value?: string;
}

type LayerImageCategoryKey = keyof typeof LayerImageCategories;
type LayerFeatureType = 'polygon' | 'line' | 'point';
type GroupNameType =
  | 'boundary_layers'
  | 'demographic_layers'
  | 'psychographic_layers'
  | 'custom_layers'
  | 'Zones'
  | 'search_layers'
  | 'traffic_layers';
interface BaseLayerConfig {
  displayName: string;
  groupName: GroupNameType;
  permission?: string;
  idLabelFlag?: boolean;
  customMinLabel?: CustomLabel;
  customMaxLabel?: CustomLabel;
  category: LayerImageCategoryKey;
  featureType: LayerFeatureType;
  isBoundary?: boolean;
  mergeGroup?: string;
  class?: string;
}

export interface TilesetSource {
  source: string;
  source_layer: string;
  label: string;
}

type BlockGroupSource = {
  label: 'Block Groups';
  source: 'luketruitt1.block_group_insights';
  source_layer: 'block_group_insights';
} & TilesetSource;

type ZipcodeSource = {
  label: 'Zip Codes';
  source: 'luketruitt1.insights_zipcode';
  source_layer: 'insights_zipcode';
} & TilesetSource;

type OtherTilesetSource = {
  label: string;
  source: string;
  source_layer: string;
} & TilesetSource;

type GeoJSONSource = {
  label: 'GeoJSON';
  source: string | FeatureCollection;
};

export type InsightTilesetSources =
  | BlockGroupSource
  | ZipcodeSource
  | OtherTilesetSource
  | GeoJSONSource;

export type Expression = Array<ExpressionValue>;
type ExpressionValue = string | number | boolean | Expression;

interface InsightLayerConfig extends BaseLayerConfig {
  type: LayerType.Insights;
  sourceTileset: InsightTilesetSources;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
}

interface EnterpriseLayerConfig extends BaseLayerConfig {
  type: LayerType.Enterprise;
  component: ControllableLayerGroup;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
}

export type LayerConfig = InsightLayerConfig | EnterpriseLayerConfig;

interface LayersDisplayStatusProps {
  showSpinner: boolean;
  textLabel: string;
}

interface LayersToRenderState {
  classified: Record<string, LayerCard[]>;
  nonClassified: LayerCard[];
}

interface MapLayersMenuProps {
  layersGroup: GroupNameType;
}

const LayersDisplayStatus = ({
  showSpinner,
  textLabel,
}: LayersDisplayStatusProps) => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    marginTop={2}
  >
    {showSpinner && (
      <CircularProgress color="primary" size={18} sx={{ marginRight: 1 }} />
    )}
    <Typography variant="body1" color="#666">
      {textLabel}
    </Typography>
  </Box>
);

const MapLayersMenu = ({ layersGroup }: MapLayersMenuProps) => {
  // TODO: Enable extra base layers for enterprise when zones fetch new style ordering layer.

  // Access the map instance
  const layers = useLayersStore((state) => state.layers);
  const setLayers = useLayersStore((state) => state.setLayers);

  const [selectedLayer, setSelectedLayer] = useState<LayerCard | null>(null);
  const [layersToRender, setLayersToRender] = useState<LayersToRenderState>({
    classified: {},
    nonClassified: [],
  });
  const [permissions, setPermissions] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  const { open: openSubscriptionDialog } = useSubscriptionDialog();

  const handleAccordionSelect =
    (selected: number) => (event: React.SyntheticEvent, isExpanded: boolean) =>
      setActiveAccordion(isExpanded ? selected : null);

  const { accessToken } = useAccessToken();
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const enabledCustomerLayers = useSettingsStore(
    (state) => state.userSettings?.customerLayers ?? []
  );
  const layerInfo = useTrafficStore((state) => state.layerInfo);

  const trafficLayerIds = layerInfo
    .filter((layer) => layer?.fields && Object.keys(layer.fields).length > 0)
    .map((layer) => `traffic_volume-${layer.id}`);

  const squareMetersPerSquareMile = 2589988.11;
  const BlockGroupBaseLayerID = 'block_groups';
  const blockGroupMatchLayerIds: string[] = useMemo(
    () => [
      BlockGroupBaseLayerID,
      ...blockGroups.map((blockGroupSet) => `BG-${blockGroupSet.groupName}`),
    ],
    [blockGroups]
  );
  const heatmapLayerIds: string[] = ['zones', 'zone-numbers'];

  const customerLayers: Record<string, LayerConfig> = {
    kidstrong_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Kidstrong',
      category: 'Fitness.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 0 - not included
        // 90th percentile: 259
        // 95th percentile: 545
        // 99th percentile: 1550
        threshold: [0, 259, 545, 1550, 6000],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'kidstrong__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'kidstrong__date_range',
        fields: [
          {
            Impressions: {
              key: 'kidstrong__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    kids_gymnastics_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Kids Gymnastics',
      category: 'Gyms.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 0 - not included
        // 90th percentile: 80
        // 95th percentile: 205
        // 99th percentile: 439
        threshold: [0, 80, 205, 439, 1400],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'kids_gymnastics__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'kids_gymnastics__date_range',
        fields: [
          {
            Impressions: {
              key: 'kids_gymnastics__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'kids_gymnastics__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'kids_gymnastics__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    topsail_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Topsail 5-21-2024',
      category: 'Restaurant.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 10
        // 90th percentile: 236
        // 95th percentile: 490
        // 99th percentile: 1067
        threshold: [0, 10, 236, 490, 1067, 6000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'topsail__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'topsail__date_range',
        fields: [
          {
            Impressions: {
              key: 'topsail__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    topsail_search_demand_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Topsail 7-7-2024',
      category: 'Restaurant.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 10
        // 90th percentile: 236
        // 95th percentile: 490
        // 99th percentile: 1067
        threshold: [0, 10, 236, 490, 1067, 6000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'topsail_v2__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'date_range',
        fields: [
          {
            Impressions: {
              key: 'topsail_v2__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail_v2__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail_v2__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    kidstrong_customer_heatmap: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Zipcode - Customer Location Heatmap',
      category: 'Restaurant.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Client Data',
        source: 'luketruitt1.client_data_zipcode',
        source_layer: 'client_data_zipcode',
      },
      styleConfig: {
        threshold: [0, 1, 5, 10, 15],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
        ],
        expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
        fallback: 0,
        unit: 'customers',
        boundLabels: ['Low', 'High'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: 'zip_code',
      },
    },
    seafood_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Seafood',
      category: 'Restaurant.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 18
        // 90th percentile: 357
        // 95th percentile: 737
        // 99th percentile: 2118
        threshold: [0, 18, 357, 737, 2118, 12000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'seafood__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'seafood__date_range',
        fields: [
          {
            Impressions: {
              key: 'seafood__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'seafood__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'seafood__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    fitness_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Fitness',
      category: 'Fitness.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 50, 530, 2115, 8400],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'fitness_demand__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'fitness_demand__date_range',
        fields: [
          {
            Impressions: {
              key: 'fitness_demand__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'fitness_demand__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'fitness_demand__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    city_lifestyle_zips: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Zip Codes',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      groupName: 'custom_layers',
      styleConfig: {
        colors: ['rgba(173, 216, 230, 0.5)'],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          { Population: 'population' },
          {
            'Median Home Value': {
              key: 'avg_median_home_value',
              format: 'dollars',
            },
          },
          {
            'Median Household Income': {
              key: 'avg_median_household_income',
              format: 'dollars',
            },
          },
          { 'Owner Occupied Homes': 'owner_occupied_homes' },
          {
            'Percent Owner Occupied': {
              key: 'percent_owner_occupied',
              format: 'percent',
            },
          },
          { 'Seasonal Population': 'seasonal_population' },
          {
            'Percent Seasonal Population': {
              key: 'percent_seasonal_population',
              format: 'percent',
            },
          },
          {
            'Count of Local Businesses': {
              expression: [
                '+',
                ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
                ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
                ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Building Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Equipment Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Specialty Contractors'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Dentists'], 0],
                ['coalesce', ['get', 'business_counts_Physicians'], 0],
                ['coalesce', ['get', 'business_counts_Florists'], 0],
                ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
                ['coalesce', ['get', 'business_counts_Design Services'], 0],
                ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
                ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Health Practitioners'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Optometrists'], 0],
                ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
                ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
                ['coalesce', ['get', 'business_counts_Hospitals'], 0],
                ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Landscaping Services'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Residential Construction'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Law Practices'], 0],
                ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
              ],
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.city-life-zipcodes',
        source_layer: 'city-life-zipcodes',
      },
    },
    city_lifestyle_places: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Cities',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      groupName: 'custom_layers',
      styleConfig: {
        colors: ['rgba(173, 216, 230, 0.5)'],
      },
      dataConfig: {
        header: 'city_id',
        fields: [
          { Population: 'population' },
          {
            'Median Home Value': {
              key: 'avg_median_home_value',
              format: 'dollars',
            },
          },
          {
            'Median Household Income': {
              key: 'avg_median_household_income',
              format: 'dollars',
            },
          },
          { 'Owner Occupied Homes': 'owner_occupied_homes' },
          {
            'Percent Owner Occupied': {
              key: 'percent_owner_occupied',
              format: 'percent',
            },
          },
          { 'Seasonal Population': 'seasonal_population' },
          {
            'Percent Seasonal Population': {
              key: 'percent_seasonal_population',
              format: 'percent',
            },
          },
          {
            'Count of Local Businesses': {
              expression: [
                '+',
                ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
                ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
                ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Building Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Equipment Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Specialty Contractors'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Dentists'], 0],
                ['coalesce', ['get', 'business_counts_Physicians'], 0],
                ['coalesce', ['get', 'business_counts_Florists'], 0],
                ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
                ['coalesce', ['get', 'business_counts_Design Services'], 0],
                ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
                ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Health Practitioners'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Optometrists'], 0],
                ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
                ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
                ['coalesce', ['get', 'business_counts_Hospitals'], 0],
                ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Landscaping Services'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Residential Construction'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Law Practices'], 0],
                ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
              ],
            },
          },
          { 'Zip Code Count': 'zip_code_count' },
          {
            'Zip Codes': {
              key: 'zip_codes',
              format: 'chips',
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Cities',
        source: 'luketruitt1.cities',
        source_layer: 'cities',
      },
    },
    city_lifestyle_local_businesses: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Local Businesses',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      groupName: 'custom_layers',
      sourceTileset: {
        label: 'City Lifestyle - Local Businesses',
        source: 'luketruitt1.local-business-zip-counts',
        source_layer: 'local-business-zip-counts',
      },
      styleConfig: {
        colors: [Color.white, Color.blue, Color.blue, Color.purple],
        threshold: [0, 50, 400, 800],
        opacityStops: [0, 0.5, 1, 1],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'total_businesses'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        unit: 'businesses / mi\u00B2',
      },
      dataConfig: {
        header: 'zip_code',
        fields: [
          { 'Count of Local Businesses': 'total_businesses' },
          {
            'Local Businesses / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'total_businesses'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    multi_family_housing: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Multi-Family Housing Units',
      category: 'Population.Income',
      groupName: 'demographic_layers',
      class: 'housing',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 100, 3000, 7500],
        colors: [Color.white, Color.yellow, Color.orange, Color.red],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'mfh_units_total'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'units / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { 'Multi-Family Units': 'mfh_units_total' },
          {
            'Units / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'mfh_units_total'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    zip_franchising_interest: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 173, 706, 1766.48, 10000],
        opacityStops: [0, 0, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'franchising_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Franchising Impressions': 'franchising_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Franchising Demand',
      permission: 'read:insight_franchising_interest',
      category: 'Retail.Franchise',
      groupName: 'search_layers',
    },
    zip_consumer_retail_intent: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 11787.08, 34384.36, 98819.33, 134000],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'retail_intent_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Retail Intent Impressions': 'retail_intent_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Consumer Demand',
      permission: 'read:insight_consumer_retail_intent',
      category: 'Retail',
      groupName: 'search_layers',
    },
    zip_search_carls_jr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 13, 27, 41, 42],
        opacityStops: [0, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'carls_junior_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Carls Jr Impressions': 'carls_junior_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: `Consumer Demand - Carls Jr`,
      permission: 'read:ent_walkons',
      category: 'Restaurant',
      groupName: 'search_layers',
    },
    designated_fuel_corridors: {
      type: LayerType.Insights,
      featureType: 'line',
      sourceTileset: {
        label: 'Aiquetech',
        source: 'luketruitt1.aiquetech',
        source_layer: 'designated_fuel_corridors',
      },
      displayName: 'Designated Fuel Corridors',
      category: 'Miscellaneous.Search American',
      groupName: 'custom_layers',
      styleConfig: { colors: ['#000'] },
    },
    power_transmission_lines: {
      type: LayerType.Insights,
      featureType: 'line',
      sourceTileset: {
        label: 'Aiquetech',
        source: 'luketruitt1.aiquetech',
        source_layer: 'power_transmission_lines',
      },
      displayName: 'Power Transmission Lines',
      styleConfig: { colors: [Color.red] },
      category: 'Miscellaneous.Search American',
      groupName: 'custom_layers',
    },
    topsail_traditional_seed_point: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Topsail Traditional Model Seed Points',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'seed_point_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen, Color.yellow, Color.red],
        threshold: [0, 1, 2, 3],
        opacityStops: [0, 1, 1, 1],
        expression: [
          'match',
          ['get', 'performance_classification'],
          'primary',
          1,
          'secondary',
          2,
          'tertiary',
          3,
          0,
        ],
        boundLabels: ['Primary', 'Tertiary'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Performance Index': 'performance_index' },
          { 'Population Density': 'pop_density' },
          {
            'Urbanization Type': {
              expression: [
                'case',
                ['<=', ['get', 'urbanicity_classification'], 3],
                'Suburban',
                ['>=', ['get', 'urbanicity_classification'], 4],
                'Urban',
                'Unknown',
              ],
            },
          },
          {
            'Urbanization Classification': {
              expression: [
                'match',
                ['get', 'urbanicity_classification'],
                0,
                'Unknown',
                1,
                'Rural',
                2,
                'In-Town',
                3,
                'Suburban',
                4,
                'Metropolitan',
                5,
                'Urban',
                6,
                'Urban Core',
                'Unknown',
              ],
            },
          },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
        ],
      },
    },
    topsail_traditional_seed_point_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Topsail Traditional Model Seed Points V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'seed_point_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.red],
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: ['case', ['has', 'taid'], 1, 0],
        hideBoundValues: false,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'CBSA' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { 'Population Density': 'pop_density' },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_sample_set: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Topsail Traditional Model Sample Set',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'sample_set_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.yellow, Color.red],
        threshold: [0, 1, 2],
        opacityStops: [0, 1, 1],
        expression: [
          'case',
          ['<=', ['get', 'urbanization_classification'], 'suburban'],
          1,
          ['>=', ['get', 'urbanization_classification'], 'urban'],
          2,
          0,
        ],
        fallback: 0,
        boundLabels: ['Suburban', 'Urban'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: 'location_id',
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Location Name': 'location_name' },
          { 'Urbanization Classification': 'urbanization_classification' },
        ],
      },
    },
    topsail_traditional_primary_urban_points: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Primary Urban Optimized Trade Areas',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            ['>=', ['get', 'urbanicity_classification'], 4],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Performance Index': 'performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Classification': 'urbanicity_classification' },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
        ],
      },
    },
    topsail_traditional_primary_suburban_points: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Primary Suburban Optimized Trade Areas',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            ['<=', ['get', 'urbanicity_classification'], 3],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Performance Index': 'performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Classification': 'urbanicity_classification' },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
        ],
      },
    },
    topsail_traditional_primary_urban_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Topsail Traditional Model - Primary Urban Drive Times',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            ['>=', ['get', 'urbanicity_classification'], 4],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Performance Index': 'performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Classification': 'urbanicity_classification' },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
        ],
      },
    },
    topsail_traditional_primary_suburban_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Topsail Traditional Model - Primary Suburban Drive Times',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            ['<=', ['get', 'urbanicity_classification'], 3],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Performance Index': 'performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Classification': 'urbanicity_classification' },
          { 'Workforce Density': 'workforce_density' },
          {
            Count: {
              expression: [
                'coalesce',
                ['get', 'count_within_0.25_miles'],
                ['get', 'count_within_0.5_miles'],
              ],
            },
          },
          {
            'Count Type': {
              expression: [
                'case',
                ['has', 'count_within_0.25_miles'],
                'within 0.25 miles',
                ['has', 'count_within_0.5_miles'],
                'within 0.5 miles',
                'N/A',
              ],
            },
          },
        ],
      },
    },
    plotr_retail_proximity_heatmap: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Plotr Retail Proximity Heatmap',
      category: 'Retail.RetailProximity',
      groupName: 'custom_layers',
      sourceTileset: {
        label: 'Retail Proximity',
        source: 'luketruitt1.generators',
        source_layer: 'generators',
      },
      styleConfig: {
        colors: [
          Color.transparent,
          '#FFFF00', // Yellow
          '#FFA500', // Orange
          '#FF0000',
        ],
        threshold: [0, 1, 5, 9],
        opacityStops: [0, 1, 1, 1],
        unit: 'retail_proximity',
        boundLabels: ['Low', 'High'],
        expression: ['get', 'retail_proximity'],
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Retail Proximity Generators': {
              key: 'retail_proximity',
              format: 'integer',
            },
          },
        ],
      },
    },
    child_development_heatmap: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Plotr Child Development Heatmap',
      category: 'Education.ChildDevelopment',
      groupName: 'custom_layers',
      sourceTileset: {
        label: 'Child Development',
        source: 'luketruitt1.generators',
        source_layer: 'generators',
      },
      styleConfig: {
        colors: [
          Color.transparent,
          '#FFFF00', // Yellow
          '#FFA500', // Orange
          '#FF0000',
        ],
        threshold: [0, 1, 3, 6],
        opacityStops: [0, 1, 1, 1],
        expression: ['get', 'child_development'],
        unit: 'child_development',
        boundLabels: ['Low', 'High'],
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Child Development Generators': {
              key: 'child_development',
              format: 'integer',
            },
          },
        ],
      },
    },
    topsail_traditional_primary_urban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Primary Urban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        imageName: 'geometry-point-pin-sdf',
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_primary_suburban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Primary Suburban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        imageName: 'geometry-point-pin-sdf',
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_primary_urban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Topsail Traditional Model - Primary Urban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_primary_suburban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName:
        'Topsail Traditional Model - Primary Suburban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.lightGreen],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'primary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_secondary_urban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Secondary Urban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.yellow],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'secondary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_secondary_suburban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Secondary Suburban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.yellow],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'secondary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_secondary_urban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Topsail Traditional Model - Secondary Urban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.yellow],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'secondary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_secondary_suburban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName:
        'Topsail Traditional Model - Secondary Suburban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.yellow],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'secondary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_tertiary_urban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Tertiary Urban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.red],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'tertiary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_tertiary_suburban_points_v2: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName:
        'Topsail Traditional Model - Tertiary Suburban Optimized Trade Areas V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_points_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.red],
        imageName: 'geometry-point-pin-sdf',
        threshold: [0, 1],
        opacityStops: [0, 1],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'tertiary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_tertiary_urban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Topsail Traditional Model - Tertiary Urban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.red],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'tertiary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    topsail_traditional_tertiary_suburban_boundaries_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName:
        'Topsail Traditional Model - Tertiary Suburban Drive Times V2',
      category: 'Restaurant.Topsail',
      groupName: 'Zones',
      sourceTileset: {
        label: 'Topsail',
        source: 'luketruitt1.topsail',
        source_layer: 'optimization_polygons_layer_v2',
      },
      styleConfig: {
        colors: [Color.transparent, Color.red],
        threshold: [0, 1],
        opacityStops: [0, 0.5],
        expression: [
          'case',
          [
            'all',
            ['==', ['get', 'performance_classification'], 'tertiary'],
            [
              'in',
              ['get', 'urbanization_type'],
              ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
            ],
          ],
          1,
          0,
        ],
        fallback: 0,
        hideBoundValues: true,
      },
      dataConfig: {
        header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
        fields: [
          { CBSA: 'cbsa' },
          { Latitude: 'latitude' },
          { Longitude: 'longitude' },
          { 'Market Ranking': 'market_ranking' },
          { 'National Ranking': 'ntl_ranking' },
          { 'Performance Classification': 'performance_classification' },
          { 'Predicted Performance Index': 'predicted_performance_index' },
          { 'Population Density': 'pop_density' },
          { 'Urbanization Type': 'urbanization_type' },
          { 'Urbanicity Type': 'urbanicity_type' },
          { 'Workforce Density': 'workforce_density' },
          { 'POI Generator Count': 'poi_generator_count' },
          { 'Top Profile Percentage': 'top_profile_pct' },
          { Seasonality: 'seasonality' },
          { Address: 'address' },
        ],
      },
    },
    supermatt_walkin_self_service_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Supermatt Walk-in Self Service',
      category: 'Laundry.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 10, 100, 500, 1000, 5000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'supermatt_walkin_self_service__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'supermatt_walkin_self_service__date_range',
        fields: [
          {
            Impressions: {
              key: 'supermatt_walkin_self_service__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'supermatt_walkin_self_service__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'supermatt_walkin_self_service__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    supermatt_pickup_delivery_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Supermatt Pickup & Delivery',
      category: 'Laundry.Search Demand',
      groupName: 'search_layers',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 10, 100, 500, 1000, 5000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'supermatt_pickup_delivery__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'supermatt_pickup_delivery__date_range',
        fields: [
          {
            Impressions: {
              key: 'supermatt_pickup_delivery__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'supermatt_pickup_delivery__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'supermatt_pickup_delivery__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
  };

  const enterpriseLayers: Record<string, LayerConfig> = useMemo(
    () => ({
      retail_proximity_heatmap: {
        type: LayerType.Enterprise,
        featureType: 'polygon',
        displayName: 'Retail Proximity Heatmap',
        component: { layerIds: heatmapLayerIds },
        styleConfig: {
          colors: ['#008000', '#FFFF00', '#FFA500', '#FF8C00', '#FF0000'],
          threshold: [8, 6, 4, 2, 0],
          unit: 'cells',
          boundLabels: ['Far', 'Near'],
        },
        permission: 'read:enterprise_retail_proximity',
        category: 'Retail.RetailProximity',
        groupName: 'custom_layers',
      },
      block_group_match: {
        type: LayerType.Enterprise,
        featureType: 'polygon',
        displayName: 'Customer Block Group Match',
        component: { layerIds: blockGroupMatchLayerIds },
        styleConfig: {
          threshold: ['C', 'B', 'A'],
          colors: [
            'rgba(54, 4, 194, 0.3)',
            'rgba(54, 4, 194, 0.65)',
            'rgba(54, 4, 194, 1)',
          ],
          unit: 'match grade',
        },
        permission: 'read:enterprise_bg_match',
        category: 'Miscellaneous',
        groupName: 'custom_layers',
      },
    }),
    [blockGroupMatchLayerIds]
  );

  const insights: {
    [key: string]: LayerConfig;
  } = useMemo(
    () => ({
      ...Object.entries(customerLayers).reduce(
        (acc, [key, value]) =>
          enabledCustomerLayers.includes(key) ? { ...acc, [key]: value } : acc,
        {}
      ),
      ...getMapLayerDefinitions(trafficLayerIds),
    }),
    [trafficLayerIds]
  );

  const options: { [key: string]: LayerConfig } = {
    ...insights,
    ...enterpriseLayers,
  };

  const layerDetailBuilder: ({
    key,
    value,
  }: {
    key: string;
    value: LayerConfig;
  }) => LayerCard = ({ key, value }) => {
    const { permission, category } = value;
    const isLocked = permission ? !permissions[permission] : false;

    return {
      ...value,
      id: key,
      insight: key,
      layerGroup: { layerIds: [key] },
      opacity: 0,
      imageUrl: LayerImageCategories[category],
      isLocked,
    };
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      if (accessToken) {
        // Explicitly typing the Set elements as string
        const permissionKeys = new Set<string>();

        // Extract permissions from insights and enterpriseLayers
        Object.values(options).forEach((item) => {
          if (item.permission) permissionKeys.add(item.permission);
        });

        const permissionsResult: Record<string, boolean> = {};
        for (const key of permissionKeys) {
          permissionsResult[key] = await checkPermission(accessToken, key);
        }

        setPermissions(permissionsResult);

        setLoading(false);
      }
    };
    fetchPermissions();
  }, [accessToken]);

  useEffect(() => {
    if (loading) return;

    const classifiedLayers: Record<string, LayerCard[]> = {};
    let nonClassifiedLayers: LayerCard[] = [];

    const mergedLayerKeys: string[] = [];

    Object.entries(options).map(([key, value]) => {
      const { groupName, mergeGroup } = value;

      if (groupName !== layersGroup) return;

      const layer = layerDetailBuilder({ key, value });

      let mergedLayers: {
        id: string;
        layersList: LayerCard[];
      } | null = null;
      if (groupName === 'demographic_layers') {
        const layersList = [layer];
        if (mergeGroup && options[mergeGroup]) {
          const relatedLayer = layerDetailBuilder({
            key: mergeGroup,
            value: options[mergeGroup],
          });
          layersList.push(relatedLayer);
          mergedLayerKeys.push(mergeGroup);
        }
        mergedLayers = {
          id: layer.id,
          layersList,
        };
      }

      const layerClass = layer.class?.toUpperCase();
      const newLayer = (mergedLayers as LayerCard) || layer;
      if (layerClass) {
        classifiedLayers[layerClass] ??= [];
        classifiedLayers[layerClass].push(newLayer);
      } else nonClassifiedLayers.push(newLayer);
    });

    Object.keys(classifiedLayers).forEach((key) => {
      classifiedLayers[key] = classifiedLayers[key].filter(
        ({ id }) => !mergedLayerKeys.includes(id)
      );
    });
    nonClassifiedLayers = nonClassifiedLayers.filter(
      ({ id }) => !mergedLayerKeys.includes(id)
    );

    setLayersToRender({
      classified: classifiedLayers,
      nonClassified: nonClassifiedLayers,
    });
  }, [loading, layersGroup]);

  const hasLayerAccess: (layer: LayerCard) => boolean = (layer) => {
    // Open the subscription modal for locked layers
    if (layer.permission && !permissions?.[layer.permission]) {
      setSelectedLayer(null);
      openSubscriptionDialog();

      return false;
    }

    return true;
  };

  const handleLayerSelect = (targetLayer: LayerCard) => {
    if (!hasLayerAccess(targetLayer)) return;

    setSelectedLayer(targetLayer);
  };

  const handleEditLayerClose = () => setSelectedLayer(null);

  const handleSaveLayer = (targetLayer: LayerCard) => {
    if (!hasLayerAccess(targetLayer)) return false;

    const savedLayersCopy: LayerCard[] = [...layers];
    const doLayerExist: number = savedLayersCopy.findIndex(
      (layer) => layer.id === selectedLayer?.id
    );
    if (doLayerExist > -1) savedLayersCopy[doLayerExist] = targetLayer;
    else savedLayersCopy.unshift(targetLayer);

    setLayers(savedLayersCopy);

    // Reset selected layer and close dialog
    setSelectedLayer(null);
    return true;
  };

  const sortLayers = (a: LayerCard, b: LayerCard) => {
    const nameA = a.displayName || '';
    const nameB = b.displayName || '';
    return nameA.localeCompare(nameB);
  };

  return (
    <>
      <EditLayerModal
        selectedLayer={selectedLayer}
        onClose={handleEditLayerClose}
        onSave={handleSaveLayer}
      />
      <Box className="custom-scrollbar">
        {!loading ? (
          <>
            {Object.keys(layersToRender.classified).length ||
            layersToRender.nonClassified.length ? (
              <>
                {Object.entries(layersToRender.classified)
                  .sort(([a], [b]) => a.localeCompare(b))
                  .map(([title, layers], index) => {
                    return (
                      <Accordion
                        key={title}
                        expanded={activeAccordion === index}
                        onChange={handleAccordionSelect(index)}
                        sx={{ marginTop: 1, boxShadow: 'none' }}
                        className="layers-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '4px 4px 0 0',
                            backgroundColor: '#00000008',
                          }}
                        >
                          <Typography fontWeight={500}>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '0 0 4px 4px',
                            borderTop: 0,
                          }}
                        >
                          {layers.sort(sortLayers).map((layer: LayerCard) => (
                            <SingleLayerCard
                              key={layer.id}
                              layer={layer}
                              onSave={handleSaveLayer}
                              onEdit={handleLayerSelect}
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                {layersToRender.nonClassified
                  .sort(sortLayers)
                  .map((layer: LayerCard) => (
                    <SingleLayerCard
                      key={layer.id}
                      layer={layer}
                      onSave={handleSaveLayer}
                      onEdit={handleLayerSelect}
                    />
                  ))}
              </>
            ) : (
              <LayersDisplayStatus
                showSpinner={false}
                textLabel="No layers in this group"
              />
            )}
          </>
        ) : (
          <LayersDisplayStatus
            showSpinner={true}
            textLabel="Fetching layers..."
          />
        )}
      </Box>
    </>
  );
};

export default MapLayersMenu;
