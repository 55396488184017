import { Box, IconButton, Popover, Typography } from '@mui/material';
import {
  plotrMultiplayerData,
  Rule,
  SourceType,
} from '@plotr/plotr-multiplayer-data/src';
import CloseIcon from '@mui/icons-material/Close';
import { HexColorPicker } from 'react-colorful';
import { useState } from 'react';
import { debounce } from 'lodash';

type RulesListProps = {
  rules: Rule[];
};

const RulesList = ({ rules }: RulesListProps) => {
  const [colorPickerAnchorEl, setColorPickerAnchorEl] =
    useState<Element | null>(null);
  const [selectedColor, setSelectedColor] = useState('#ff0000');
  const [editingRuleId, setEditingRuleId] = useState<string | null>(null);
  const rulesetsMethods = plotrMultiplayerData.methods?.rulesets;

  const handleColorChange = debounce((newColor: string) => {
    setSelectedColor(newColor);
    if (editingRuleId) {
      const existingRule = rules.find(
        (rule: { id: string }) => rule.id === editingRuleId
      );
      if (!existingRule) return;
      rulesetsMethods?.updateRule(editingRuleId, {
        ...existingRule,
        effect: newColor,
      });
    }
  }, 1000);

  const handleColorPickerOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ruleId: string
  ) => {
    setSelectedColor(
      rules.find((rule: { id: string }) => rule.id === ruleId)?.effect ||
        '#ff0000'
    );
    setColorPickerAnchorEl(event.currentTarget);
    setEditingRuleId(ruleId);
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchorEl(null);
    setEditingRuleId(null);
  };

  return (
    <Box pt={1}>
      {rules.map((rule) => (
        <Box key={rule.id} display="flex" justifyContent="space-between" p={1}>
          <Typography height={'fit-content'} my={'auto'}>
            {rule.sourceType === SourceType.KeyValue
              ? rule.propertyKey
              : rule.sourceType}
            : {rule.evaluation.value}
          </Typography>
          <Box display="flex">
            <IconButton
              onClick={(event) => {
                handleColorPickerOpen(event, rule.id);
              }}
              size="small"
              sx={{
                color: 'transparent',
                backgroundColor: rule.effect,
                marginRight: 1,
                '&:hover': { backgroundColor: rule.effect },
              }}
            >
              <Box width={24} height={24} />
            </IconButton>
            <IconButton
              onClick={() => rulesetsMethods?.deleteRule(rule.id)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Popover
        open={Boolean(colorPickerAnchorEl)}
        anchorEl={colorPickerAnchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box minHeight={'13rem'} p={1}>
          <HexColorPicker color={selectedColor} onChange={handleColorChange} />
        </Box>
      </Popover>
    </Box>
  );
};

export default RulesList;
