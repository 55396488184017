import { useState } from 'react';

import {
  LockIcon,
  LockableButton,
} from '~/src/common/components/LockableButton';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import type {
  DemographicData,
  DemographicField,
} from '../services/demographicSearch';

import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

function formatField(field: DemographicField) {
  if (field.value == null) {
    return 'Unknown';
  }

  if (field.type === 'percent') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: 0,
    });

    return formatter.format(field.value);
  }

  if (field.type === 'USD') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    return formatter.format(field.value);
  }

  if (field.type === 'integer') {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    });

    return formatter.format(field.value);
  }

  return field.value.toLocaleString();
}

const BlurText = ({
  children,
  intensity,
}: {
  children: string;
  intensity?: number;
}) => {
  return (
    <span
      style={{
        filter: `blur(${Math.floor(intensity ?? 1)}px)`,
        userSelect: 'none', // don't allow selecting text
      }}
    >
      {children}
    </span>
  );
};

const SummaryCard = ({ data }: { data: DemographicData }) => {
  const isLocked = data.locked ?? false;

  const [isHovered, setIsHovered] = useState(false);
  const { open: openSubscriptionDialog } = useSubscriptionDialog();

  return (
    <LockableButton isLocked={isLocked} onClick={openSubscriptionDialog}>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  backgroundColor: '#333',
                  color: '#fff',
                  padding: '8px',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                <Box display={'flex'}>
                  <Typography
                    variant="h6"
                    sx={{
                      flex: 11,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data.name}
                  </Typography>
                  <Box flex={1}>
                    {isLocked ? (
                      <LockIcon
                        active={isHovered}
                        styles={{ fontSize: '22px', margin: '-4px 0 0 -4px' }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.fields.map((field, index) => (
              <Tooltip
                title={field.description || ''}
                placement="right"
                key={field.name}
              >
                <TableRow
                  sx={{ backgroundColor: index % 2 ? '#e6edf9' : '#ffffff' }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                    width={'75%'}
                  >
                    {field.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 'none',
                      width: '25%',
                      textAlign: 'right',
                    }}
                  >
                    {isLocked ? (
                      <BlurText intensity={3}>{formatField(field)}</BlurText>
                    ) : (
                      formatField(field)
                    )}
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </Card>
    </LockableButton>
  );
};

export const SummaryDisplay = ({
  dataPoints,
}: {
  dataPoints: DemographicData[];
}) => {
  return (
    <Box minHeight={'fit-content'} paddingBottom={'.5em'} id="test">
      <Grid container justifyContent={'center'} spacing={2}>
        {dataPoints.map((dataSummary) => (
          <Grid item key={dataSummary.name} xs={11}>
            <SummaryCard data={dataSummary} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SummaryDisplay;
