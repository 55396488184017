import React, { ReactNode, useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Collapse,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MobileDataConfig } from '~/src/constants';

interface StatDisplayProps {
  value?: ReactNode;
  label: string;
  fontSizeTitle?: number;
  isTruncated?: boolean;
  sx?: object;
  infoText?: string;
  tooltipPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  description: ReactNode;
}

const StatDisplay: React.FC<StatDisplayProps> = ({
  value,
  label,
  fontSizeTitle = MobileDataConfig.MOBILE_DATA_DESCRIPTION_LABEL_FONT_SIZE,
  sx,
  infoText,
  tooltipPlacement = 'bottom',
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
        padding: '10px',
        borderRadius: '4px',
        background: 'linear-gradient(135deg, #f3f4f6 0%, #e5e7eb 100%)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={handleToggle}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{value}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {infoText ? (
            <Tooltip title={infoText} placement={tooltipPlacement}>
              <Typography
                fontSize={fontSizeTitle}
                align="center"
                color="gray"
                sx={{ cursor: 'pointer', marginRight: '8px' }}
              >
                {label}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              fontSize={fontSizeTitle}
              align="center"
              color="gray"
              sx={{ marginRight: '8px' }}
            >
              {label}
            </Typography>
          )}
          <IconButton size="small" aria-expanded={isOpen}>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Divider sx={{ marginY: 1 }} />
        <Typography
          fontSize={fontSizeTitle}
          align="left"
          color="gray"
          sx={{ marginLeft: 2 }}
        >
          {description}
        </Typography>
      </Collapse>
    </Box>
  );
};

export default StatDisplay;
