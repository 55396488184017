import { memo, useMemo, useState } from 'react';
import tinycolor from 'tinycolor2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import usePOIStore from '../../dynamic-map/hooks/usePOIStore';

interface POIMenuProps {
  isCustomPOIBrands?: boolean;
}

export const POIMenu = (props: POIMenuProps) => {
  const { isCustomPOIBrands = false } = props;

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const togglePoisEnabled = useDynamicMapStore(
    (state) => state.togglePoisEnabled
  );

  const mfhEnabled = useDynamicMapStore((state) => state.mfhEnabled);
  const toggleMFHEnabled = useDynamicMapStore(
    (state) => state.toggleMFHEnabled
  );

  const selectedMFH = useDynamicMapStore((state) => state.selectedMFH);
  const toggleCompletedMFH = useDynamicMapStore(
    (state) => state.toggleCompletedMFH
  );
  const toggleUnderConstructionMFH = useDynamicMapStore(
    (state) => state.toggleUnderConstructionMFH
  );

  const poiGroups = usePOIStore((state) => state.poiGroups);
  const selectedPOIGroups = useDynamicMapStore(
    (state) => state.selectedPOIGroups
  );
  const togglePOIGroup = useDynamicMapStore((state) => state.togglePOIGroup);

  const selectedBrands = useDynamicMapStore((state) => state.selectedBrands);
  const toggleBrand = useDynamicMapStore((state) => state.toggleBrand);

  const resetPOIs = useDynamicMapStore((state) => state.resetPOIs);

  const selectedBrandIds = selectedBrands.map((brand) => brand.id);

  const expandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.expandedMobileDataAccordion
  );
  const setExpandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.setExpandedMobileDataAccordion
  );

  interface MemoizedChipProps {
    label: string;
    clickable?: boolean;
    onClick?: () => void;
    industry: string;
    color?: string;
    style?: React.CSSProperties;
  }

  const MemoizedChip = memo((props: MemoizedChipProps) => {
    const { label, onClick, color } = props;
    const textColor = tinycolor(color).getLuminance() < 0.6 ? '#fff' : '#000';
    const chipStyle = {
      margin: '0.2rem',
      backgroundColor: color,
      color: color === 'default' ? undefined : textColor,
    };

    return <Chip label={label} onClick={onClick} style={chipStyle} />;
  });
  MemoizedChip.displayName = 'POIBrandName';

  const poiGroupData = useMemo(() => {
    const customPOIBrands = ['Competitor Locations', 'Proxy Brand Locations'];

    const filteredPOIGroups = poiGroups
      .map(({ group, color, brandResults, queryResults }) => ({
        name: group,
        color: color,
        brandGroup: [...brandResults, ...queryResults],
      }))
      .filter(
        ({ name }) => isCustomPOIBrands === customPOIBrands.includes(name)
      );

    if (!isCustomPOIBrands)
      filteredPOIGroups.push({
        // HACK: This is a special case for multifamily housing.
        // TODO: Is there a way we can handle this like we do other POI groups (even though they're not really POIs)?
        name: 'Multifamily Housing',
        color: '#ff0000',
        brandGroup: [],
      });

    return filteredPOIGroups;
  }, [isCustomPOIBrands]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '-7px',
          paddingBottom: 1,
        }}
      >
        <FormControlLabel
          control={
            <Switch checked={poisEnabled} onChange={togglePoisEnabled} />
          }
          label="Show Points of Interest"
          sx={{ mr: 1 }}
        />
        <Button onClick={resetPOIs} sx={{ padding: 0 }}>
          Clear all
        </Button>
      </Box>
      <Divider />
      {poisEnabled && (
        <div className="custom-scrollbar">
          {poiGroupData.map((poiGroup) => {
            if (poiGroup.name === 'Multifamily Housing') {
              return (
                <Accordion
                  key="mfh-accordion"
                  expanded={expandedMobileDataAccordion === 'mfh-accordion'}
                  onChange={(_, isExpanded) => {
                    setExpandedMobileDataAccordion(
                      isExpanded ? 'mfh-accordion' : false
                    );
                  }}
                  sx={{ margin: '0 !important' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor:
                        expandedMobileDataAccordion === 'mfh-accordion'
                          ? (theme) => theme.palette.grey[200]
                          : 'inherit',
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox checked={mfhEnabled} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleMFHEnabled();
                      }}
                      label="Multifamily Housing"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <MemoizedChip
                        label="🏢 Completed"
                        clickable
                        color={
                          selectedMFH.completed
                            ? '#21ff06' // green
                            : '#ddd'
                        }
                        industry={'mfh'}
                        onClick={toggleCompletedMFH}
                        style={{ margin: '0.2rem' }}
                      />
                      <MemoizedChip
                        label="🚧 Under Construction"
                        clickable
                        color={
                          selectedMFH.underConstruction
                            ? '#ffff0a' // yellow
                            : '#ddd'
                        }
                        industry={'mfh'}
                        onClick={toggleUnderConstructionMFH}
                        style={{ margin: '0.2rem' }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }

            const isChecked = selectedPOIGroups.includes(poiGroup.name);

            return (
              <Accordion
                key={poiGroup.name}
                expanded={expandedMobileDataAccordion === poiGroup.name}
                onChange={(e, isExpanded) => {
                  setExpandedMobileDataAccordion(
                    isExpanded ? poiGroup.name : false
                  );
                }}
                sx={{ margin: '0 !important' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor:
                      expandedMobileDataAccordion === poiGroup.name
                        ? (theme) => theme.palette.grey[200]
                        : 'inherit',
                  }}
                >
                  <FormControlLabel
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePOIGroup(poiGroup.name);
                    }}
                    control={<Checkbox checked={isChecked} />}
                    label={poiGroup.name}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {poiGroup.brandGroup
                      .sort((a, b) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                      )
                      .map((brand) => {
                        const isBrandSelected = selectedBrandIds.includes(
                          brand.id
                        );
                        return (
                          <MemoizedChip
                            key={brand.id}
                            label={brand.name}
                            clickable
                            industry={poiGroup.name}
                            color={isBrandSelected ? poiGroup.color : '#ddd'}
                            onClick={() => {
                              toggleBrand(brand.id);
                            }}
                            style={{ margin: '0.2rem' }}
                          />
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </>
  );
};
