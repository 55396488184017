import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  plotrMultiplayerData,
  Rule,
  SourceType,
  TargetType,
} from '@plotr/plotr-multiplayer-data/src';
import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  AddKeyRuleSchema,
  AddKeyRuleSchemaType,
  AddTagRuleSchema,
  AddTagRuleSchemaType,
} from '~/src/validation/rules.schema';

type AddRuleFormProps = {
  closeForm: () => void;
  targetType: TargetType;
};

const AddRuleForm = ({ closeForm, targetType }: AddRuleFormProps) => {
  const [selectedColor, setSelectedColor] = useState('#ff0000');
  const rulesetsMethods = plotrMultiplayerData.methods?.rulesets;
  const [showPropertyKeyInput, setShowPropertyKeyInput] = useState(false);
  const [showRuleValueInput, setShowRuleValueInput] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddKeyRuleSchemaType | AddTagRuleSchemaType>({
    resolver: zodResolver(
      showPropertyKeyInput ? AddKeyRuleSchema : AddTagRuleSchema
    ),
    defaultValues: { ruleType: '' as SourceType },
  });

  const handleAddRule: SubmitHandler<
    AddKeyRuleSchemaType | AddTagRuleSchemaType
  > = (data: AddKeyRuleSchemaType | AddTagRuleSchemaType) => {
    const newRule: Rule = {
      id: `${crypto.randomUUID()}`,
      targetType,
      sourceType: data.ruleType,
      evaluation: {
        operator: '=',
        value:
          data.ruleType === SourceType.Tag
            ? data.ruleValue.toString().toLocaleLowerCase()
            : data.ruleValue,
      },
      effect: selectedColor,
      propertyKey:
        data.ruleType === SourceType.KeyValue ? data.ruleKeyValue : undefined,
    };

    rulesetsMethods?.addRule(newRule);

    setShowPropertyKeyInput(false);
    setShowRuleValueInput(false);
    closeForm();
  };

  return (
    <Box display="flex" flexDirection={'column'} gap={1}>
      <Box component={'form'} onSubmit={handleSubmit(handleAddRule)}>
        <Controller
          control={control}
          name="ruleType"
          render={({ field: { onChange, ...props } }) => (
            <Select
              {...props}
              sx={{ marginBottom: 1 }}
              onChange={(e) => {
                const selectedType = e.target.value;
                setShowPropertyKeyInput(selectedType === SourceType.KeyValue);
                setShowRuleValueInput(selectedType !== '');
                onChange(e);
              }}
              displayEmpty
              fullWidth
              error={!!errors.ruleType}
            >
              <MenuItem disabled value="">
                Select Rule Type
              </MenuItem>
              {Object.values(SourceType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.ruleType?.message && (
          <Typography color="red">{errors.ruleType.message}</Typography>
        )}
        {showPropertyKeyInput && (
          <Controller
            control={control}
            name="ruleKeyValue"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.ruleKeyValue}
                fullWidth
                label="Property Key"
                margin="normal"
              />
            )}
          />
        )}
        {errors.ruleKeyValue?.message && showPropertyKeyInput && (
          <Typography color="red">{errors.ruleKeyValue.message}</Typography>
        )}

        {showRuleValueInput && (
          <Controller
            control={control}
            name="ruleValue"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.ruleValue}
                fullWidth
                label="Rule Value"
                margin="normal"
              />
            )}
          />
        )}
        {errors.ruleValue?.message && showRuleValueInput && (
          <Typography color="red">{errors.ruleValue.message}</Typography>
        )}
      </Box>
      <HexColorPicker
        color={selectedColor}
        onChange={setSelectedColor}
        style={{ marginLeft: 'auto', marginRight: 'auto', height: '100px' }}
      />
      <Box ml={'auto'}>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          onClick={handleSubmit(handleAddRule)}
        >
          Save Rule
        </Button>
        <Button sx={{ marginLeft: 1 }} color="secondary" onClick={closeForm}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AddRuleForm;
