import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { memo, MouseEvent, useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';
import { LockIcon } from '~/src/common/components/LockableButton';
import formatDayTime from '~/src/common/helpers/formatDayTime';
import { customDayTimeTrafficMarks, layerOpacityMarks } from '~/src/constants';
import getMatchOpacity from '~/src/features/dynamic-map/helpers/getMatchOpacity';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useBlockGroupsStore from '../../../../dynamic-map/hooks/useBlockGroupStore';
import useLayersStore, {
  LayerCard,
} from '../../../../dynamic-map/hooks/useLayersStore';
import useTrafficStore from '../../../../dynamic-map/hooks/useTrafficStore';

const CensusMatchButtonGroup = () => {
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const selectedBlockGroups = useDynamicMapStore(
    (state) => state.selectedBlockGroups
  );
  const setSelectedBlockGroups = useDynamicMapStore(
    (state) => state.setSelectedBlockGroups
  );

  return (
    <ToggleButtonGroup
      value={selectedBlockGroups}
      onChange={(_, selectedGroups: string[]) => {
        setSelectedBlockGroups(selectedGroups);
      }}
      aria-label="toggle layer groups"
      exclusive={false}
      sx={{ marginRight: 1 }}
    >
      {blockGroups.map((blockGroup) => {
        const backgroundColor = `rgba(54, 4, 194, ${getMatchOpacity(
          blockGroup.groupName
        )})`;
        const textColor =
          tinycolor(backgroundColor).getLuminance() < 0.6 ? '#fff' : '#000';
        const hoverColor = tinycolor(backgroundColor).darken(10).toHexString();

        return (
          <ToggleButton
            key={blockGroup.groupName}
            value={`block_group_match-${blockGroup.groupName}`}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.action.disabledBackground,
              '&.Mui-selected': {
                backgroundColor,
                color: textColor,
                '&:hover': {
                  backgroundColor: hoverColor,
                },
              },
            }}
          >
            {blockGroup.groupName}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

interface SingleLayerCardProps {
  layer: LayerCard;
  onSave: (layerDetails: LayerCard) => boolean;
  onEdit: (layerDetails: LayerCard) => void;
}

const SingleLayerCard = memo((props: SingleLayerCardProps) => {
  const { layer, onSave, onEdit } = props;
  const mergedLayers = layer.layersList;

  const { layers, updateLayer, deleteLayer } = useLayersStore((state) => state);

  const initiallyActiveMergeLayer =
    layers.find(({ id }) =>
      [mergedLayers?.[0]?.id, mergedLayers?.[1]?.id].includes(id)
    )?.id ||
    mergedLayers?.[0]?.id ||
    '';

  const [layerDetails, setLayerDetails] = useState<LayerCard>(layer);
  const [hidden, setHidden] = useState<boolean>(true);
  const [layerOpacity, setLayerOpacity] = useState<number>(0);
  const [activeMergeLayer, setActiveMergeLayer] = useState<string>(
    initiallyActiveMergeLayer
  );
  const [useCustomDayTime, setUseCustomDayTime] = useState(false);

  const { id, displayName, isLocked } = layerDetails;
  const savedLayer = layers.find(
    ({ id }) => id === (activeMergeLayer || layer.id)
  );

  useEffect(() => {
    const selectedFromMerged = mergedLayers?.find(
      ({ id }) => id === activeMergeLayer
    );

    const currentLayer = savedLayer || selectedFromMerged || layer;
    setLayerDetails(currentLayer);
    setHidden(currentLayer.opacity === 0);
    setLayerOpacity(currentLayer.opacity * 100);
  }, [activeMergeLayer, layers]);

  const handleBoundaryTypeChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (savedLayer) {
      const connectedLayer =
        mergedLayers?.find(({ id }) => id === value) || savedLayer;
      onSave({
        ...connectedLayer,
        opacity: savedLayer.opacity,
        displayName: displayName.split(' - ')[1] || displayName,
      });
      handleDeleteLayer();
    }
    setActiveMergeLayer(value);
  };

  const trafficEnabled = useTrafficStore((state) => state.trafficEnabled);
  const setDayPart = useTrafficStore((state) => state.setTrafficSelectionHour);

  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);

  const handleLayerOpacityChange = (newValue: number) => {
    if (!isLocked) setLayerOpacity(newValue);
  };

  const handleLayerOpacityCommit = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
    const opacityValue = singleValue / 100;

    let hasPermission: boolean = true;
    if (!savedLayer && opacityValue)
      hasPermission = onSave({
        ...layerDetails,
        opacity: opacityValue,
        displayName: mergedLayers ? displayName.split(' - ')[1] : displayName,
      });
    // Save the new layer when the user applies it using either the slider or visibility icon
    else updateLayer(id, { opacity: opacityValue }); // Update the layer opacity here

    if (!hasPermission) return;

    setLayerOpacity(singleValue);
    setHidden(opacityValue === 0);
  };

  const onLayerEdit = () => {
    const targetLayer = { ...layerDetails };
    if (mergedLayers)
      targetLayer.displayName = displayName.split(' - ')[1] || displayName;

    onEdit(targetLayer);
  };

  const handleToggleClick = (e: MouseEvent<HTMLButtonElement>) =>
    handleLayerOpacityCommit(e, !hidden ? 0 : 100);

  const handleDeleteLayer = () => {
    if (savedLayer?.id) deleteLayer(savedLayer.id);
  };

  useEffect(() => {
    if (useCustomDayTime) setDayPart(12);
    else setDayPart(-1);
  }, [setDayPart, useCustomDayTime]);

  const TrafficController = () => {
    const trafficSelection = useTrafficStore((state) => state.trafficSelection);
    const setDayType = useTrafficStore((state) => state.setTrafficSelectionDay);
    const setDayPart = useTrafficStore(
      (state) => state.setTrafficSelectionHour
    );

    const handleTrafficOptionChange = (event: {
      target: { value: string };
    }) => {
      const isCustomDayTime = !!Number(event.target.value);
      setUseCustomDayTime(isCustomDayTime);
      if (isCustomDayTime) {
        // Set default values when switching to custom day/time
        setDayType(1); // Example: set to Sunday
        setDayPart(12); // Example: set to noon
      } else {
        // Set to average daily traffic
        setDayType(0); // 0 for average of all days
        setDayPart(-1); // -1 for average of all hours
      }
    };

    return (
      <Box marginTop={2}>
        <Box textAlign="center">
          <Select
            labelId="demo-simple-select-helper-label"
            value={useCustomDayTime ? '1' : '0'}
            onChange={handleTrafficOptionChange}
          >
            <MenuItem value="0">Average Daily Traffic</MenuItem>
            <MenuItem value="1">Custom Day/Time Traffic</MenuItem>
          </Select>
        </Box>
        {trafficEnabled && useCustomDayTime && (
          <Box marginTop={2}>
            {/* ToggleButtonGroup with Max Width */}
            <Box flex="1" pr={1} sx={{ maxWidth: 300 }}>
              {/* Adjust the max-width as per your design */}
              <ToggleButtonGroup
                aria-label="days of the week"
                value={trafficSelection.selectedDay}
                exclusive
                fullWidth={false} // Prevent full width
              >
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(
                  (day, index) => (
                    <ToggleButton
                      key={day}
                      value={index + 1}
                      color={
                        trafficSelection?.selectedDay === index
                          ? 'primary'
                          : 'secondary'
                      }
                      onClick={() => {
                        setDayType(index + 1);
                      }}
                    >
                      {day}
                    </ToggleButton>
                  )
                )}
              </ToggleButtonGroup>
            </Box>
            <Slider
              defaultValue={12}
              step={1}
              marks={customDayTimeTrafficMarks}
              min={6}
              max={23}
              onChange={(event, newValue) => setDayPart(newValue as number)}
              sx={{ py: 2 }}
            />
            <Typography variant="body1" textAlign="center" fontWeight={500}>
              {formatDayTime(
                trafficSelection.selectedDay,
                trafficSelection.selectedHour
              )}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        marginTop: 1,
        borderColor: id === savedLayer?.id ? 'secondary.main' : '#0000001f',
      }}
    >
      <Box padding={2}>
        {mergedLayers && (
          <Box margin="-5px 0 10px">
            <Select
              className="merged-layers-dropdown"
              value={activeMergeLayer}
              onChange={handleBoundaryTypeChange}
              disabled={mergedLayers?.length === 1}
            >
              {mergedLayers.map(({ id, displayName }) => {
                return (
                  <MenuItem value={id} key={id}>
                    {displayName.split(' - ')[0].toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <Typography variant="body1" fontWeight={500}>
            {isLocked && (
              <LockIcon
                active={false}
                styles={{ fontSize: '22px', margin: '-4px 0 0 -4px' }}
              />
            )}{' '}
            {mergedLayers
              ? displayName?.split(' - ')?.[1] || displayName
              : displayName}
          </Typography>
          <Box marginTop="-5px" display="flex">
            {/* Not used for now */}
            {/* <Tooltip title="Edit Layer" placement="top">
              <IconButton onClick={onLayerEdit} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>*/}
            <Tooltip
              title={id === savedLayer?.id ? 'Remove Layer' : 'Add Layer'}
              placement="top"
            >
              <Checkbox
                checked={id === savedLayer?.id}
                onChange={(event) => {
                  if (event.target.checked) {
                    onSave({ ...layerDetails, opacity: 0.8 });
                  } else {
                    // Delete layer
                    handleDeleteLayer();
                  }
                }}
                size="small"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Tooltip title="Toggle Visibility (0% - 100%)">
            <IconButton
              size="small"
              onClick={handleToggleClick}
              sx={{ marginLeft: -0.5 }}
            >
              {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <Slider
            value={layerOpacity}
            marks={layerOpacityMarks}
            step={null}
            min={0}
            max={100}
            aria-label="Transparency"
            size="small"
            valueLabelDisplay="auto"
            valueLabelFormat={() => `${layerOpacity}%`}
            onChange={(event, newValue) =>
              handleLayerOpacityChange(newValue as number)
            }
            onChangeCommitted={handleLayerOpacityCommit}
            sx={{ mx: 2 }}
          />
        </Box>
        {id === 'block_group_match' && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              pt: 1,
              alignItems: 'center',
            }}
          >
            <CensusMatchButtonGroup />
            <Typography component="span">
              {blockGroups
                ? 'Block Group Match Grade'
                : 'Census Tract Match Grade'}
            </Typography>
          </Box>
        )}
        {id === 'traffic_volume' && <TrafficController />}
      </Box>
    </Paper>
  );
});
SingleLayerCard.displayName = 'LayerItem';

export default SingleLayerCard;
