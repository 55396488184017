import { parseEnv } from '@plotr/common-utils';
import * as turfHelper from '@turf/helpers';
import { FeatureCollection, Geometry } from 'geojson';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export interface ClientGeometry {
  filename: string;
  data: FeatureCollection<Geometry>;
  cacheTime: number;
}

const CACHE_VALID_DURATION = 60 * 60 * 1000;

export async function fetchClientGeometries(
  apiPath: string,
  cachedClientGeometries: ClientGeometry[]
): Promise<ClientGeometry[]> {
  const updatedClientGeometries: ClientGeometry[] = [];
  const filenames = await fetchListOfGeometryFiles(apiPath);

  // Current time for cache freshness check
  const now = new Date().getTime();

  for (const filename of filenames) {
    // Check if file is cached and fresh
    const cacheEntry = cachedClientGeometries.find(
      (cg) =>
        cg.filename === filename && now - cg.cacheTime < CACHE_VALID_DURATION
    );

    if (cacheEntry) {
      updatedClientGeometries.push(cacheEntry);
      continue;
    }

    // Fetch the file if not cached or cache is stale
    const fileResponse = await fetch(`${env.API_V2}${apiPath}/${filename}`);
    if (!fileResponse.ok) {
      console.warn(`Failed to fetch file: ${filename}`);
      continue;
    }

    const data: FeatureCollection<Geometry> = await fileResponse.json();

    // Add fetched file to the cache with current timestamp
    updatedClientGeometries.push({
      data: turfHelper.featureCollection(data.features),
      filename,
      cacheTime: now, // Add timestamp to track when this entry was cached
    });
  }

  return updatedClientGeometries;
}

async function fetchListOfGeometryFiles(apiPath: string): Promise<string[]> {
  return fetch(`${env.API_V2}${apiPath}`).then(async (response) => {
    if (!response.ok) {
      throw new Error(
        `HTTP ${response.status} ${response.statusText}: ${response.text()}`
      );
    }
    const filenames = (await response.json()) as string[];
    return filenames;
  });
}
