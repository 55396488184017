import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import HubspotContactForm from '~/src/common/components/HubspotContactForm';

interface HubspotModalProps {
  open: boolean;
  onClose: () => void;
  formType: 'mobile' | 'sonar';
}

const HubspotModal: React.FC<HubspotModalProps> = ({
  open,
  onClose,
  formType,
}) => {
  const title =
    formType === 'mobile' ? 'Mobile Data Access' : 'Sonar Data Access';
  const description =
    formType === 'mobile'
      ? 'Get access to detailed mobile data insights for this location. Fill out the form below to request access.'
      : 'Unlock Sonar data to assess the likelihood of store closures. Complete the form to request access to this feature.';

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" paragraph>
          {description}
        </Typography>
        <HubspotContactForm type={formType} onFormSubmit={onClose} />
      </Box>
    </Modal>
  );
};

export default HubspotModal;
