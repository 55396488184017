import { useState, useEffect } from 'react';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import { checkPermission } from '~/src/global/services/permissionService';

const usePermissionCheck = (permissionKey: string) => {
  const { accessToken } = useAccessToken();
  const [hasPermission, setHasPermission] = useState<boolean | null>(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (accessToken) {
        const permissionGranted = await checkPermission(
          accessToken,
          permissionKey
        );
        setHasPermission(permissionGranted);
      }
    };
    fetchPermissions();
  }, [accessToken, permissionKey]);

  return hasPermission;
};

export default usePermissionCheck;
