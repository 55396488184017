import { Box, Typography } from '@mui/material';
import React from 'react';

interface ErrorFetchingDataProps {
  errorContent: any; // Allow any type but handle object rendering
}

const ErrorFetchingData: React.FC<ErrorFetchingDataProps> = ({
  errorContent,
}) => {
  const displayContent =
    typeof errorContent === 'object'
      ? JSON.stringify(errorContent)
      : errorContent;

  return (
    <Box
      sx={{
        border: '1px solid #FFC0CB',
        padding: 2,
        margin: 2,
        marginTop: 4,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      {displayContent && (
        <Typography variant="body1">{displayContent}</Typography>
      )}
    </Box>
  );
};

export default ErrorFetchingData;
