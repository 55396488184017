import { parse, format, startOfMonth, endOfMonth } from 'date-fns';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

function convertDateStringToISOFormat(
  dateString: string,
  isStartDate: boolean
): string {
  // Correct the format string to match 'MMM dd yyyy'
  const date = parse(dateString, 'MMM dd yyyy', new Date());

  // Check if the date is valid
  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid date format');
  }

  // Format the date based on whether it's a start or end date
  const formattedDate = isStartDate
    ? format(startOfMonth(date), 'yyyy-MM-dd')
    : format(endOfMonth(date), 'yyyy-MM-dd');

  return formattedDate;
}

function validateDates(startDate: string, endDate: string): boolean {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if dates are valid and in the correct format
  if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
    console.error('Invalid date format. Dates should be in YYYY-MM-DD format.');
    return false;
  }

  // Check if end date is after start date
  if (end <= start) {
    console.error('End date must come after start date.');
    return false;
  }

  return true;
}

// creating a new Date object in JavaScript using a string results in
// the date as being interpreted in the UTC time zone by default
// this function converts the date to the local time zone
// this is used to display the date in the local time zone
function convertToLocalDate(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  const localDate = new Date(year, month - 1, day);
  return localDate;
}

function calculateAgeOfStore(openedOn: string | null): string {
  if (openedOn) {
    const openedDate = new Date(openedOn);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - openedDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    const days = diffDays % 30;

    let ageString = '';
    if (years > 0) {
      ageString += `${years} years`;
    }
    if (months > 0) {
      ageString += `${ageString.length > 0 ? ', ' : ''}${months} months`;
    }
    if (days > 0) {
      ageString += `${ageString.length > 0 ? ', ' : ''}${days} days`;
    }

    return ageString.length > 0 ? ageString : 'Less than a day old';
  }
  return '';
}

const checkFeatureType = (clickedOnThisFeature: MapboxGeoJSONFeature) => {
  const isPOI =
    clickedOnThisFeature.sourceLayer === 'poi-places' ||
    clickedOnThisFeature.sourceLayer === 'multi_family';

  const isDefinedTerritory =
    clickedOnThisFeature.source === 'defined-territories';

  const isDrawnTerritory =
    clickedOnThisFeature.source === 'custom-drawn-territories';

  const isTerritory = isDefinedTerritory || isDrawnTerritory;

  return { isPOI, isTerritory };
};

const calculatePercentile = (rank: number, total: number) => {
  if (rank && total) {
    const rankingValue = total - rank;
    const percentileRank = (rankingValue / total) * 100;
    return `${percentileRank.toFixed(2)}%`;
  }
  return 'N/A';
};

const getLastFiscalQuarter = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-based index

  let fiscalStartMonth, fiscalEndMonth;

  // Determine the last fiscal quarter based on the current month
  if (currentMonth >= 0 && currentMonth <= 2) {
    // Q1 (Jan - Mar) - Previous quarter is Q4 (Oct - Dec)
    fiscalStartMonth = 9; // October
    fiscalEndMonth = 11; // December
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    // Q2 (Apr - Jun) - Previous quarter is Q1 (Jan - Mar)
    fiscalStartMonth = 0; // January
    fiscalEndMonth = 2; // March
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // Q3 (Jul - Sep) - Previous quarter is Q2 (Apr - Jun)
    fiscalStartMonth = 3; // April
    fiscalEndMonth = 5; // June
  } else {
    // Q4 (Oct - Dec) - Previous quarter is Q3 (Jul - Sep)
    fiscalStartMonth = 6; // July
    fiscalEndMonth = 8; // September
  }

  // Calculate start and end dates for the last fiscal quarter
  const fiscalStartDate = new Date(
    currentDate.getFullYear(),
    fiscalStartMonth,
    1
  );
  const fiscalEndDate = new Date(
    currentDate.getFullYear(),
    fiscalEndMonth + 1,
    0
  ); // Last day of the fiscal quarter

  return {
    fiscalStartDate: fiscalStartDate.toISOString().split('T')[0], // Convert to YYYY-MM-DD
    fiscalEndDate: fiscalEndDate.toISOString().split('T')[0], // Convert to YYYY-MM-DD
  };
};

export {
  convertDateStringToISOFormat,
  validateDates,
  calculateAgeOfStore,
  convertToLocalDate,
  checkFeatureType,
  calculatePercentile,
  getLastFiscalQuarter,
};
