import { Box } from '@mui/material';
import { Rule, SourceType, TargetType } from '@plotr/plotr-multiplayer-data';
import CollapseCard from '~/src/common/components/CollapseCard';
import RulesCollapseSection from '~/src/features/rules-collapse';

export const getColor = (
  rulesets: Rule[],
  featureProperties: { [key: string]: string[] | string }
) => {
  // Default color
  let color = '#888';
  rulesets.forEach((rule) => {
    if (rule.targetType === TargetType.Territories) {
      if (
        rule.sourceType === SourceType.KeyValue &&
        rule.propertyKey &&
        featureProperties[rule.propertyKey] === rule.evaluation.value
      ) {
        color = rule.effect;
      }
      if (rule.sourceType === SourceType.Tag) {
        if (
          featureProperties.tags &&
          featureProperties.tags.includes(rule.evaluation.value as string)
        ) {
          color = rule.effect;
        }
      }
    }
  });
  return color;
};

const GlobalTerritorySettingsMenu = () => {
  return (
    <CollapseCard title="Global Territory Settings">
      <Box p={2}>
        <RulesCollapseSection targetType={TargetType.Territories} />
      </Box>
    </CollapseCard>
  );
};

export default GlobalTerritorySettingsMenu;
