import { parseEnv } from '@plotr/common-utils';
import { polygon } from '@turf/helpers';
import {
  Feature,
  Geometry,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import { useCallback } from 'react';

import axios from 'axios';
import useGeometryCache, {
  GeometryCacheEntry,
} from '~/src/common/hooks/useGeometryCache';
import {
  CustomTerritory,
  TerritoryType,
} from '../../../../../plotr-multiplayer-data/src';
import fitMapToBoundary from '../helpers/fitMapToBoundary';
import useMapContext from './useMapContext';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

const useFlyToTerritory = () => {
  const map = useMapContext();
  const geometryCache = useGeometryCache();

  const isGeometryWithCoordinates = (
    geometry: Geometry
  ): geometry is
    | Point
    | LineString
    | Polygon
    | MultiPoint
    | MultiLineString
    | MultiPolygon => {
    return 'coordinates' in geometry;
  };

  const flyToTerritory = useCallback(
    async (territory: CustomTerritory) => {
      if (map == null || Object.keys(territory.boundaries).length === 0) return;

      const zoomRangeFourToSixteen = new Array(12).fill(0).map((_, i) => i + 4);
      const potentialCacheKeys = zoomRangeFourToSixteen.map(
        (zoom) => `${territory.id}::${zoom}`
      );
      const cachedTerritories = potentialCacheKeys.map((key) =>
        geometryCache.get(key)
      );

      const highestQualityCached =
        cachedTerritories.reduce<GeometryCacheEntry | null>((acc, curr) => {
          if (
            curr &&
            isGeometryWithCoordinates(curr.data) &&
            acc &&
            isGeometryWithCoordinates(acc?.data)
          ) {
            if (
              !acc ||
              curr.data.coordinates.length > (acc.data.coordinates?.length ?? 0)
            ) {
              return curr;
            }
          }
          return acc;
        }, null);

      if (highestQualityCached != null) {
        fitMapToBoundary(
          map,
          highestQualityCached.data as unknown as Feature<Polygon>
        );
        return;
      }

      if (territory.type === TerritoryType.Custom) {
        const firstBoundary = Object.values(territory.boundaries)[0];
        if (firstBoundary && firstBoundary.coordinates) {
          const polygonBoundary = polygon([firstBoundary.coordinates]);
          fitMapToBoundary(map, polygonBoundary as unknown as Feature<Polygon>);
          return;
        }
      }

      const zipcodes = Object.values(territory.boundaries).map((boundary) =>
        boundary.id.padStart(5, '0')
      );

      try {
        const { data } = await axios.post(
          `${env.API_V2}/merge`,
          {
            zipcodes,
            bbox: false,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );

        fitMapToBoundary(map, data);
      } catch (err) {
        console.error('Error fetching merged boundaries:', err);
      }
    },
    [map, geometryCache]
  );

  return flyToTerritory;
};

export default useFlyToTerritory;
