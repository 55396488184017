import { parseEnv } from '@plotr/common-utils';
import { FeatureCollection, GeoJsonProperties, Point } from 'geojson';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export type ClientZonesByUrbanArea = {
  [cityName: string]: FeatureCollection<Point> & {
    properties: GeoJsonProperties;
  };
};

export async function fetchClientZones(
  apiPath: string
): Promise<ClientZonesByUrbanArea | null> {
  console.debug('Fetching client zones...');

  const response = await fetch(env.API_V2 + apiPath);

  if (!response.ok) {
    const responseBody = await response.text();
    throw new Error(
      `HTTP ${response.status} ${response.statusText}: ${responseBody}`
    );
  }
  const zones = (await response
    .json()
    .catch(() => null)) as ClientZonesByUrbanArea | null;

  const zonesCount = Object.values(zones ?? {}).flat().length;

  if (zones == null || zonesCount === 0) {
    console.warn(`No zones found for at ${apiPath}`);
    return null;
  }

  console.debug(`Client zones fetched! ${zonesCount} zones found.`);

  return zones;
}
