import { useAuth0 } from '@auth0/auth0-react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { parseEnv } from '@plotr/common-utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '~/src/assets/images/plotr_logo.svg';
import useDynamicMapStore from '../../features/dynamic-map/hooks/useDynamicMapStore';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import './NavBar.css';

const env = parseEnv({
  AUTH0_NAMESPACE: process.env.AUTH0_NAMESPACE,
  STRIPE_CUSTOMER_PORTAL_URL: process.env.STRIPE_CUSTOMER_PORTAL_URL,
});

type MenuButtonProps = {
  children: React.ReactNode;
} & (
  | {
      to: string;
      newTab?: boolean;
    }
  | {
      onClick: () => void;
    }
);

const MenuButton = (props: MenuButtonProps) => {
  const hoverStyle = {
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.1)',
    },
  };

  return 'to' in props ? (
    <Link to={props.to} target={props.newTab ? '_blank' : undefined}>
      <MenuItem disableRipple>
        <Button fullWidth sx={hoverStyle}>
          {props.children}
        </Button>
      </MenuItem>
    </Link>
  ) : (
    <MenuItem disableRipple onClick={props.onClick}>
      <Button fullWidth sx={hoverStyle}>
        {props.children}
      </Button>
    </MenuItem>
  );
};

const NavBar = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuth0();

  const isStripeCustomer =
    user?.[env.AUTH0_NAMESPACE + 'stripe_customer_id'] != null;

  const profilePic = user?.picture;

  const customerPortalURL = `${
    env.STRIPE_CUSTOMER_PORTAL_URL
  }?prefilled_email=${encodeURIComponent(user?.email ?? '')}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
      paddingX={2}
      height="7vh"
    >
      <img
        src={logo}
        style={{ height: '2.5rem', cursor: 'pointer' }}
        alt="home"
        onClick={() => {
          window.location.reload();
        }}
      />
      <div className="header">
        <PopupState variant="popover" popupId="account-dropdown">
          {(popupState) => (
            <>
              <Button {...bindTrigger(popupState)}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    {popupState.isOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar
                      alt="profile-pic"
                      src={profilePic}
                      sx={{ width: '2rem', height: '2rem' }}
                    />
                  </Grid>
                </Grid>
              </Button>
              <Menu {...bindMenu(popupState)}>
                {user?.email ? (
                  <MenuItem
                    divider
                    disableRipple
                    sx={{
                      cursor: 'default',
                      userSelect: 'auto',
                    }}
                  >
                    <Typography variant="body2">{user.email}</Typography>
                  </MenuItem>
                ) : null}
                {isStripeCustomer && (
                  <MenuButton to={customerPortalURL} newTab>
                    Manage Account
                  </MenuButton>
                )}
                {/* TODO: decide what to do with these client-specific links */}
                {location.pathname.match(/\/dashboard\/diesel\/?$/) && (
                  <MenuButton to="/diesel/leasesites/">Leases</MenuButton>
                )}
                {location.pathname.match(/\/diesel\/leasesites\/?$/) && (
                  <MenuButton to="/dashboard/diesel">Dashboard</MenuButton>
                )}
                <MenuButton
                  onClick={() => {
                    useDynamicMapStore.persist.clearStorage();
                    useDemographicStore.persist.clearStorage();
                    if (isAuthenticated) {
                      logout({
                        logoutParams: {
                          returnTo: window.location.origin,
                        },
                      });
                    } else {
                      // HACK refreshes the page for client users for now
                      navigate(0);
                    }
                  }}
                >
                  Sign Out
                </MenuButton>
              </Menu>
            </>
          )}
        </PopupState>
      </div>
    </Box>
  );
};

export default NavBar;
