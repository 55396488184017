import { parseEnv } from '@plotr/common-utils/src';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

export const getPointLocation = async (lng: number, lat: number) => {
  const locationRawResponse = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${env.MAPBOX_API_KEY}`
  );
  const location = await locationRawResponse.json();

  return (
    location.features.find((feature: { id: string; place_name: string }) =>
      feature.id.includes('country')
    ) ?? null
  );
};
