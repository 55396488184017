import { preventDuplicateItems } from '@plotr/common-utils';
import { useEffect } from 'react';

import useAccessToken from '~/src/global/hooks/useAccessToken';
import type { UserSettings } from '~/src/global/services/userSettingsService';
import defaultPOIGroupRequests from '../../../data/defaults/default_poi_groups.json';
import useHeatmapStore from '../components/CustomLayersSource/hooks/useHeatmapStore';
import useBlockGroupsStore from './useBlockGroupStore';
import useClientGeometriesStore from './useClientGeometriesStore';
import usePOIStore from './usePOIStore';
import useTrafficStore from './useTrafficStore';
import useZonesStore from './useZonesStore';
// FIXME: hide enterprise layer options that are not available to the user
export default function useUserResources(
  userSettings: UserSettings | Record<string, never> | null
) {
  const { accessToken, isLoading: tokenLoading } = useAccessToken();

  const poiGroupsError = usePOIStore((state) => state.error);
  const poiGroupsLoading = usePOIStore((state) => state.isLoading);
  const fetchPOIGroups = usePOIStore((state) => state.fetchPOIGroups);
  const poiGroupRequests = userSettings?.poiGroups;

  const userSettingsLoading = userSettings == null;

  // Fetch user-specific POI data
  useEffect(() => {
    if (userSettingsLoading) return;

    // fetch default groups
    fetchPOIGroups(defaultPOIGroupRequests).then(() => {
      // fetch user groups
      return poiGroupRequests != null
        ? fetchPOIGroups(poiGroupRequests, preventDuplicateItems('group'))
        : undefined;
    });
  }, [poiGroupRequests, fetchPOIGroups, userSettingsLoading]);

  const blockGroupsError = useBlockGroupsStore((state) => state.error);
  const blockGroupsLoading = useBlockGroupsStore((state) => state.isLoading);
  const fetchBlockGroups = useBlockGroupsStore(
    (state) => state.fetchBlockGroups
  );
  const blockGroupAPIPath =
    userSettings?.enterpriseLayerConfig?.blockGroupMatches?.apiPath;

  // Fetch block-group matches, if available
  useEffect(() => {
    if (accessToken == null || blockGroupAPIPath == null) return;

    fetchBlockGroups(blockGroupAPIPath);
  }, [accessToken, blockGroupAPIPath, fetchBlockGroups]);

  const heatmapLoading = useHeatmapStore((state) => state.isLoading);
  const fetchLayerInfoBySource = useHeatmapStore(
    (state) => state.fetchLayerInfoBySource
  );
  const heatmapMapboxURLs =
    userSettings?.enterpriseLayerConfig?.retailProximityHeatmap?.mapboxURLs;

  // Fetch heatmap layer data, if available
  useEffect(() => {
    if (accessToken == null || heatmapMapboxURLs == null) return;

    fetchLayerInfoBySource(heatmapMapboxURLs);
  }, [accessToken, heatmapMapboxURLs, fetchLayerInfoBySource]);

  const zonesError = useZonesStore((state) => state.error);
  const zonesLoading = useZonesStore((state) => state.isLoading);
  const fetchZones = useZonesStore((state) => state.fetchZones);
  const zonesAPIPath = userSettings?.clientZonesAPIPath;

  // Fetch client zones, if available
  useEffect(() => {
    if (accessToken == null || zonesAPIPath == null) return;

    fetchZones(zonesAPIPath);
  }, [accessToken, zonesAPIPath, fetchZones]);

  const userGeoError = useClientGeometriesStore((state) => state.error);
  const userGeoLoading = useClientGeometriesStore((state) => state.isLoading);
  const fetchClientGeometries = useClientGeometriesStore(
    (state) => state.fetchClientGeometries
  );
  const cachedClientGeometries = useClientGeometriesStore(
    (state) => state.clientGeometries || []
  );

  const userGeoAPIPath = userSettings?.userGeoAPIPath;

  // Fetch client geometries, if available
  useEffect(() => {
    if (accessToken == null || userGeoAPIPath == null) return;

    fetchClientGeometries(userGeoAPIPath, cachedClientGeometries);
  }, [accessToken, userGeoAPIPath, fetchClientGeometries]);

  const trafficError = useTrafficStore((state) => state.error);
  const trafficLoading = useTrafficStore((state) => state.isLoading);
  const fetchTraffic = useTrafficStore((state) => state.fetchLayerInfo);
  const trafficEnabled = useTrafficStore((state) => state.trafficEnabled);

  // Fetch traffic data, if available
  useEffect(() => {
    if (accessToken == null || !trafficEnabled) return;

    fetchTraffic();
  }, [accessToken, trafficEnabled, fetchTraffic]);

  const isLoading =
    tokenLoading ||
    userSettingsLoading ||
    poiGroupsLoading ||
    blockGroupsLoading ||
    heatmapLoading ||
    zonesLoading ||
    userGeoLoading ||
    trafficLoading;

  // Log any errors to console
  useEffect(() => {
    if (poiGroupsError != null) {
      console.error('Error fetching POI groups:', poiGroupsError);
    }
  }, [poiGroupsError]);
  useEffect(() => {
    if (blockGroupsError != null) {
      console.error('Error fetching block groups:', blockGroupsError);
    }
  }, [blockGroupsError]);
  useEffect(() => {
    if (zonesError != null) {
      console.error('Error fetching zones:', zonesError);
    }
  }, [zonesError]);
  useEffect(() => {
    if (userGeoError != null) {
      console.error('Error fetching user geometries:', userGeoError);
    }
  }, [userGeoError]);
  useEffect(() => {
    if (trafficError != null) {
      console.error('Error fetching traffic data:', trafficError);
    }
  }, [trafficError]);

  return { isLoading };
}
