import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export default function getPinURL({
  website,
  color,
  image,
  iconRatio,
  circleRatio,
  crop,
  background,
}: {
  website?: string;
  color?: string;
  image?: string;
  iconRatio?: number;
  circleRatio?: number;
  crop?: boolean;
  background?: string;
}) {
  const queryParams = new URLSearchParams({
    ...(website && { website }),
    ...(image && { image }),
    ...(color && { color }),
    ...(iconRatio && { iconRatio: iconRatio.toString() }),
    ...(circleRatio && { circleRatio: circleRatio.toString() }),
    ...(crop && { crop: crop.toString() }),
    ...(background && { background }),
  });

  return `${env.API_V2}/asset/pin?${queryParams.toString()}`;
}
