import { debounce } from '@plotr/common-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CircularProgress,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Grid,
  Box,
} from '@mui/material';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';

import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { useGenerateReport } from '~/src/features/dynamic-map/hooks/useGenerateReport';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { checkPermission } from '~/src/global/services/permissionService';
import useDemographicStore, {
  searchSettings,
} from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import { PointBoundaryType } from '~/src/features/demographic-point-lookup/services/getPointBoundary';
import CreatePulseTerritoryModal from './CreatePulseTerritoryModal';

const BOUNDARY_TYPES = ['radius', 'driving', 'walking', 'cycling'] as const;

const PointEvalMenu = () => {
  const [canDownloadReport, setCanDownloadReport] = useState(false);

  const searchRadius = useDemographicStore(
    (state) => state.demographicSearchRadius
  );
  const setSearchRadius = useDemographicStore(
    (state) => state.setDemographicSearchRadius
  );

  const debouncedSetSearchRadius = useCallback(debounce(setSearchRadius, 500), [
    setSearchRadius,
  ]);

  const [localRadius, setLocalRadius] = useState(searchRadius);

  const { accessToken } = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      checkPermission(accessToken, 'read:idealspot_reporting').then(
        setCanDownloadReport
      );
    }
  }, [accessToken]);

  useEffect(() => {
    setLocalRadius(searchRadius);
  }, [searchRadius]);

  const searchType: PointBoundaryType = useDemographicStore(
    (state) => state.boundaryType
  );
  const setSearchType = useDemographicStore(
    (state) => state.setDemographicBoundaryType
  );

  useEffect(() => {
    const validMarks = searchSettings[searchType].marks.map(
      (mark) => mark.value
    );
    if (!validMarks.includes(localRadius)) {
      setLocalRadius(validMarks[0]);
    }
  }, [localRadius, searchType]);

  useEffect(() => {
    if (localRadius !== null) {
      debouncedSetSearchRadius(localRadius);
    }
  }, [localRadius, debouncedSetSearchRadius]);

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const customPins = useCustomPins();

  const evaluatedPin = useMemo(() => {
    return evaluatedPinId == null
      ? null
      : (customPins.find(({ id }) => id === evaluatedPinId) ?? null);
  }, [customPins, evaluatedPinId]);

  const pinPos = useMemo(() => {
    const lng = evaluatedPin?.pos.lng;
    const lat = evaluatedPin?.pos.lat;

    return lat == null || lng == null ? null : { lng, lat };
  }, [evaluatedPin]);

  const {
    generate,
    isLoading: reportIsLoading,
    error: reportError,
    url: reportUrl,
  } = useGenerateReport({
    lat: pinPos?.lat,
    lng: pinPos?.lng,
    accessToken,
  });

  const commonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
  };

  return (
    <Grid
      container
      spacing={2}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sx={commonStyle}>
        <ButtonGroup fullWidth>
          {BOUNDARY_TYPES.map((type) => (
            <Button
              key={type}
              onClick={() => setSearchType(type as typeof searchType)}
              variant={searchType === type ? 'contained' : 'outlined'}
              fullWidth
            >
              {type}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sx={commonStyle}>
        <Select
          value={localRadius}
          onChange={(e) => setLocalRadius(Number(e.target.value))}
          fullWidth
        >
          {searchSettings[searchType].marks.map((mark) => (
            <MenuItem key={mark.value} value={mark.value}>
              {mark.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sx={commonStyle}>
        <CreatePulseTerritoryModal selectedPin={evaluatedPin} />
      </Grid>
      {canDownloadReport && (
        <Grid item xs={12} sx={commonStyle}>
          <Button
            startIcon={
              reportIsLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloudDownloadIcon />
              )
            }
            onClick={
              reportUrl ? () => window.open(reportUrl, '_blank') : generate
            }
            variant={reportUrl ? 'contained' : 'outlined'}
            disabled={reportIsLoading}
            fullWidth
          >
            {reportIsLoading
              ? 'Generating...'
              : reportUrl
                ? 'Report Ready - View Here'
                : reportError
                  ? 'Report Generation Failed - Retry?'
                  : 'Download Full Report'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PointEvalMenu;
