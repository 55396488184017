import { parseEnv } from '@plotr/common-utils';
import axios, { AxiosError } from 'axios';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export async function generateReport(
  latitude: number,
  longitude: number,
  accessToken: string,
  reportTemplateId?: string
): Promise<string | null> {
  try {
    const { data } = await axios.post(
      `${env.API_V2}/report`,
      { latitude, longitude, reportTemplateId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data.url;
  } catch (error) {
    console.error(
      'Error generating report:',
      error instanceof Error || error instanceof AxiosError
        ? error.message
        : error
    );
    return null;
  }
}
